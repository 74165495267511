import { Gutter } from "antd/es/grid/row";

export const FORM_GUTTER: Gutter | [Gutter, Gutter] = [8, 8];

export const LOGO_SIZE_NORMAL = 230;
export const LOGO_SIZE_SMALL = 100;

export const COLOR_WHITE = "#ffffff";
export const COLOR_VIOLA = "#47082c";
export const COLOR_VIOLA_MEDIUM = "#ededff";
export const COLOR_VIOLA_LIGHT = "#f6f6ff";
export const COLOR_ORANGE = "#ff4a1a";
export const COLOR_RED = "#FF2424";
export const COLOR_GREEN = "#7BEE6C";
export const COLOR_YELLOW = "#f1c40f";
