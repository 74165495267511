import moment, { Moment } from "moment";

export const dateToMoment = (date?: Date) => {
  if (date === undefined) return null;
  return moment(date);
};

export const stringToMoment = (date?: string) => {
  if (date === undefined || date === "") return null;
  return moment(date);
};

export const momentToString = (date?: Moment | null) => {
  if (date === undefined || date === null) return "";
  return date.format("YYYY-MM-DD");
};

export const momentToDate = (date?: Moment | null) => {
  if (date === undefined || date === null) return "";
  return new Date(momentToString(date));
};

export const momentToStringDateTime = (date?: Moment | null) => {
  if (date === undefined || date === null) return "";
  return date.toISOString();
};

export const momentToStringDateTimeStartDay = (date?: Moment | null) => {
  if (date === undefined || date === null) return "";
  const momentDate = moment(date).startOf("day");
  return momentDate.toISOString();
};

export const momentToStringDateTimeEndDay = (date?: Moment | null) => {
  if (date === undefined || date === null) return "";
  const momentDate = moment(date).endOf("day");
  return momentDate.toISOString();
};

export const dropTime = (date?: Moment | null): Moment | null => {
  if (date === undefined || date === null) return null;
  return moment(date.format("YYYY-MM-DD"));
};

export const formatDate = (format: string, date?: string | Moment | null) => (date ? moment(date).format(format) : "");

export const todayDateMoment = moment().startOf("day");
export const getYearAgoDateMoment = (years: number) => moment().startOf("day").subtract(years, "y");

export const todayDateStr = () => new Date().toISOString().slice(0, 10);

export const todayDateTimeStr = () => new Date().toISOString();

export function formattedToMmGgYyyy(value?: string) {
  if (value === undefined || value === "") return "";
  const [gg, mm, yyyy] = value.split("/");
  return `${yyyy}-${mm}-${gg}`;
}

export function mmGgYyyyToFormatted(value?: string) {
  if (value === undefined || value === "") return "";
  const [yyyy, mm, gg] = value.split("-");
  return `${gg}/${mm}/${yyyy}`;
}
