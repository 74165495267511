import { Button, Card, Col, Row, Space, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import masterCard from "../assets/svg/masterCard.svg";
import visa from "../assets/svg/visa.svg";
import AppLoading from "../components/fields/AppLoading";
import Header from "../components/Header";
import { FORM_GUTTER } from "../config/layout";
import { InfoPagamento } from "../generated/api";
import { api } from "../shared/api";
import { catchErrorHandlerApi, goToPayment } from "../shared/api-utilis";
import { goToTop } from "../shared/goToTop";
import { getPathProposta } from "../shared/path-utils";
import { loadProdottoAction } from "../store/prodotto/prodotto.actions";
import { prodottoState } from "../store/prodotto/prodotto.state";

const confermaProposta = api.proposta.confermaProposta;

const { Title } = Typography;

export const Pagamento: React.FC = () => {
  const { code, propostaId } = useParams();
  const { prodotto } = useSnapshot(prodottoState);
  const [pagamento, setPagamento] = useState<InfoPagamento>();

  const getBack = useMemo(() => code && propostaId && getPathProposta(code, propostaId), [code, propostaId]);

  const payment = useCallback(
    () => goToPayment(pagamento?.parametri?.publicKey as string, pagamento?.parametri?.sessionId as string),
    [pagamento]
  );

  useEffect(() => {
    if (!prodotto) {
      loadProdottoAction(code ?? "");
    }
  }, [code, prodotto]);

  useEffect(() => {
    goToTop();
    if (propostaId) {
      confermaProposta(propostaId)
        .then((response) => {
          // console.log(response.data);
          setPagamento(response.data);
        })
        .catch(catchErrorHandlerApi);
    }
  }, [propostaId]);

  return !pagamento && !prodotto ? (
    <AppLoading />
  ) : (
    <React.Fragment>
      <Header goTo={"Torna a riepilogo"} navigateTo={getBack} />
      <div className="pagamento">
        <Title className="fontWeightBold">Pagamento</Title>
        <Row gutter={FORM_GUTTER}>
          <Col span={24}>
            <Title level={5} className="fontWeightBold">
              Metodi di pagamento
            </Title>
          </Col>
        </Row>
        <Row gutter={FORM_GUTTER}>
          <Col span={24}>
            <Card>
              <Space size="large" align="center">
                <div>Carta di credito</div>
                <Space>
                  <img src={visa} alt="Visa" width={67} />
                  <img src={masterCard} alt="MasterCard" width={67} />
                </Space>
              </Space>
            </Card>
          </Col>
        </Row>
        <Row gutter={FORM_GUTTER} className="mt4">
          <Col span={24} className="center">
            <p>Cliccando su completa acquisto eseguirai il pagamento</p>
          </Col>
        </Row>
        <Row gutter={FORM_GUTTER}>
          <Col span={24} className="center">
            <Button onClick={payment} disabled={!pagamento} loading={!pagamento}>
              Completa il pagamento
            </Button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
