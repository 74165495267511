import { Step } from "../../types/generics";
import { stepsState } from "./steps.state";

export const setSteps = (step: number) => {
  stepsState.step = step;
};

export const goToPrevSteps = () => {
  stepsState.step = --stepsState.step;
};

export const goToNextSteps = () => {
  stepsState.step = ++stepsState.step;
};

export const setStepData = (newData: Step) => {
  stepsState.data = { ...stepsState.data, ...newData };
};
