import { Form, FormItemProps, Input, InputRef } from "antd";
import React, { Ref } from "react";
import ReadOnly from "./controls/ReadOnly";

interface IProps extends FormItemProps {
  autoFocus?: boolean;
  inputRef?: Ref<InputRef>;
  placeholder?: string;
  readonly?: boolean;
}

const InputField: React.FC<IProps> = ({ autoFocus, inputRef, placeholder, readonly = false, ...rest }) => {
  return (
    <Form.Item {...rest}>
      {readonly ? <ReadOnly /> : <Input autoFocus={autoFocus} placeholder={placeholder} ref={inputRef} />}
    </Form.Item>
  );
};

export default InputField;
