import React from "react";

interface IProps {
  checked?: boolean;
  checkedLabel: string;
  unCheckedLabel: string;
}

const ReadOnlyBoolean: React.FC<IProps> = ({ checked, checkedLabel, unCheckedLabel }) => {
  return (
    <div>
      {checked === true ? checkedLabel : null}
      {checked === false ? unCheckedLabel : null}
    </div>
  );
};

export default ReadOnlyBoolean;
