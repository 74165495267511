import { Card, Col, Row, Typography } from "antd";
import moment, { Moment } from "moment";
import { useMemo } from "react";
import { DATE_FORMAT, EMPTY, TIME_FORMAT } from "../../../config/constants";
import { Premio } from "../../../generated/api";
import { todayDateMoment } from "../../../shared/date-utils";
import { getUnitaDurata, getValueFromValueIdPremioValuePremio } from "../../../shared/options-utilis";
import { ISelectOption } from "../../../types/ISelectOption";
import ReadOnlyPrice from "../../fields/controls/ReadOnlyPrice";

const { Title } = Typography;

type IProps = {
  premio?: Premio;
  durata?: ISelectOption;
  persone?: ISelectOption;
  dataInizio?: Moment | null;
};

const InfoCard: React.FC<IProps> = ({ premio, durata, persone, dataInizio }) => {
  const totale = useMemo(() => {
    if (premio && durata && persone) {
      return (premio.prezzo ?? 0) * getValueFromValueIdPremioValuePremio(durata) * (persone?.value as number);
    } else {
      return 0;
    }
  }, [premio, durata, persone]);

  const orarioInizio = useMemo(() => {
    if (dataInizio) {
      if (dataInizio.isSame(todayDateMoment, "day")) {
        return dataInizio.format(TIME_FORMAT);
      } else {
        return "00:00";
      }
    }
  }, [dataInizio]);

  const durataGiorni = useMemo(
    () => durata && (getUnitaDurata(premio?.unitaDurata) as number) * getValueFromValueIdPremioValuePremio(durata),
    [durata, premio]
  );

  const dataFine = useMemo(
    () =>
      dataInizio &&
      moment(dataInizio)
        .add(durataGiorni && durataGiorni - 1, "days")
        .format(DATE_FORMAT),
    [dataInizio, durataGiorni]
  );

  return (
    <Card bordered={false} className="card card__viola">
      <Row>
        <Col span={24}>
          <small>Persone assicurate</small>
          <p>{persone ? persone.value : EMPTY}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <small>Giorni coperti dall`assicurazione</small>
          <p>{durataGiorni ?? EMPTY}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <small>Data inizio e fine copertura</small>
          <p>
            {dataInizio ? (
              <>
                dalle {orarioInizio} del {dataInizio.format(DATE_FORMAT)} <br />
                alle 23:59 del {dataFine}
              </>
            ) : (
              EMPTY
            )}
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <small>Costo totale</small>
          <Title>
            <ReadOnlyPrice value={totale} />
          </Title>
        </Col>
      </Row>
    </Card>
  );
};

export default InfoCard;
