import { Button, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import ok from "../../assets/svg/ok.svg";
import AppLoading from "../../components/fields/AppLoading";
import Header from "../../components/Header";
import Link from "../../components/Link";
import { CHECKOUT_FINISH } from "../../config/constants";
import { LOGO_SIZE_SMALL } from "../../config/layout";
import { Proposta } from "../../generated/api";
import { api } from "../../shared/api";
import { catchErrorHandlerApi } from "../../shared/api-utilis";
import { goToTop } from "../../shared/goToTop";
import { loadProdottoAction } from "../../store/prodotto/prodotto.actions";
import { prodottoState } from "../../store/prodotto/prodotto.state";

const recuperaProposta = api.proposta.recuperaProposta;

const { Title } = Typography;

const StatusOk: React.FC = () => {
  const { propostaId } = useParams();
  const { prodotto } = useSnapshot(prodottoState);
  const [proposta, setProposta] = useState<Proposta>();

  useEffect(() => {
    if (!prodotto && proposta) {
      loadProdottoAction(proposta?.codiceProdotto ?? "");
    }
  }, [proposta, prodotto]);

  useEffect(() => {
    goToTop();
    if (propostaId) {
      recuperaProposta(propostaId)
        .then((response) => {
          setProposta(response.data);
        })
        .catch(catchErrorHandlerApi);
    }
  }, [propostaId]);

  return !proposta && !prodotto ? (
    <AppLoading />
  ) : (
    <React.Fragment>
      <Header showLogo={true} sizeLogo={LOGO_SIZE_SMALL} />
      <div className="status status_ok center">
        <Row className="mb2">
          <Col span={24}>
            <img src={ok} alt="Status - OK" width={100} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title className="fontWeightBold">
              Grazie <br /> <span className="text-color text-color--orange">{proposta?.contraente.nome}</span>
            </Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p className="fontWeightBold">
              L`acquisto della polizza {prodotto?.nome} è andato a buon fine. Ora potrai vivere la tua esperienza in
              tranquillità!
            </p>
            <p>
              Ti abbiamo inviato una email di riepilogo con le informazioni di dettaglio della polizza acquistata e
              della documentazione di prodotto.
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="center">
            <Button>
              <Link type="a" path={CHECKOUT_FINISH} target="_self" label="Torna all`app" />
            </Button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default StatusOk;
