import { Card, Col, Row } from "antd";
import Title from "antd/lib/typography/Title";
import parse from "html-react-parser";
import { useMemo } from "react";
import { FiFileText } from "react-icons/fi";
import { Premio, Prodotto } from "../../../generated/api";
import ReadOnlyPrice from "../../fields/controls/ReadOnlyPrice";
import IconMini from "../../IconMini";
import Link from "../../Link";
import WrapIcon from "../../WrapIcon";
import { getSetInformativo } from "../Step0";

type IProps = {
  prodotto: Prodotto | null;
  prodottoLoaded: boolean;
  showTitle?: boolean;
  premio?: Premio;
  isProposta?: boolean;
};

const ProductCard: React.FC<IProps> = ({ prodotto, prodottoLoaded, premio, isProposta = false, showTitle = false }) => {
  const setInformativo = useMemo(
    () => prodotto && getSetInformativo(prodotto, prodottoLoaded),
    [prodotto, prodottoLoaded]
  );

  return (
    <Card bordered={false} className="card card--product mb1">
      <IconMini icon={prodotto?.iconaMini} />
      {showTitle && <Title className="fontWeightBold">{prodotto?.nome}</Title>}
      {prodotto?.coperture.map((copertura, index) => (
        <Row key={index}>
          <Col span={24}>
            <small>{copertura.nome}</small>
            <p>{parse(copertura.descrizione)}</p>
          </Col>
        </Row>
      ))}
      <Row>
        <Col span={24}>
          <small>Costo</small>
          <p>
            <b>
              {!isProposta && `da `}
              <ReadOnlyPrice value={premio?.prezzo} /> al giorno per persona assicurata
            </b>
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <p>
            <Link
              type="a"
              path={setInformativo?.path}
              label={setInformativo?.nome}
              icon={<WrapIcon Icon={FiFileText} />}
            />
          </p>
        </Col>
      </Row>
    </Card>
  );
};

export default ProductCard;
