import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LayoutApp from "./layouts/LayoutApp";
import NotFound from "./pages/NotFound";
import { Pagamento } from "./pages/Pagamento";
import { Proposta } from "./pages/Proposta";
import StatusKo from "./pages/status/StatusKo";
import StatusOk from "./pages/status/StatusOk";
import { Steps } from "./pages/Steps";

export const AppRoutes: React.FC = () => (
  <BrowserRouter basename={process.env.APP_BASE_PATH || "/"}>
    <Routes>
      <Route path={"/*"} element={<LayoutApp />}>
        <Route path="prodotto/:code" element={<Steps />} />
        <Route path="prodotto/:code/proposta/" element={<Proposta />} />
        <Route path="prodotto/:code/proposta/:propostaId" element={<Proposta />} />
        <Route path="prodotto/:code/proposta/:propostaId/pagamento" element={<Pagamento />} />
        <Route path="proposta/:propostaId/ok" element={<StatusOk />} />
        <Route path="proposta/:propostaId/ko" element={<StatusKo />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
