{
  "codice": "MOWI",
  "documenti": [
    {
      "nome": "Set Informativo e Informativa Privacy",
      "path": "https://5072b0f5706e.ngrok.io/resources/public/1_set_informativo_e_informativa_privacy.pdf"
    },
    {
      "nome": "Allegato 3",
      "path": "https://5072b0f5706e.ngrok.io/resources/public/2_allegato_3.pdf"
    },
    {
      "nome": "Allegato 4",
      "path": "https://5072b0f5706e.ngrok.io/resources/public/3_allegato_4.pdf"
    }
  ],
  "attivita": [
    {
      "nome": "Sci",
      "logo": null
    },
    {
      "nome": "Snowboard",
      "logo": null
    },
    {
      "nome": "Sci di Fondo",
      "logo": null
    },
    {
      "nome": "Slittino",
      "logo": null
    },
    {
      "nome": "Utilizzo Ciaspole da Neve",
      "logo": null
    },
    {
      "nome": "Pattinaggio",
      "logo": null
    },
    {
      "nome": "Escursioni",
      "logo": null
    },
    {
      "nome": "Arrampicata",
      "logo": null
    }
  ],
  "nome": "Styla Bike",
  "descrizione": "La copertura pensata per i bikers che garantisce un rimborso spese forfettario da infortunio e un capitale RC per affrontare in serenitàle tue giornate in bicicletta",
  "logo": "/resources/public/prodotto/MOWI-logo.png",
  "compagnia": {
    "nome": "Netinsurance",
    "url": "https://www.netinsurance.it/",
    "logo": "/resources/public/compagnia/netinsurance.svg"
  },
  "coperture": [
    {
      "nome": "Rimborso Spese da Infortunio",
      "descrizione": "Rimborso Spese da Infortunio",
      "dettaglio": null,
      "documenti": [
        {
          "nome": "Copertura RC",
          "path": "https://5072b0f5706e.ngrok.io/resources/public/5_copertura_rc.pdf"
        }
      ],
      "massimale": 250000,
      "franchigia": null
    },
    {
      "nome": "Responsabilità civile",
      "descrizione": "Responsabilità civile",
      "dettaglio": null,
      "documenti": [],
      "massimale": 250000,
      "franchigia": null
    }
  ],
  "numeroMinPersone": 1,
  "numeroMaxPersone": 5,
  "premi": [
    {
      "id": 1,
      "nome": "1 Giorno",
      "tipo": "DURATA",
      "unitaDurata": "GIORNO",
      "durataMin": 1,
      "durataMax": 5,
      "dataFine": null,
      "prezzo": 3.5
    }
  ],
  "campiAdeguatezza": [
    {
      "chiave": "no-altre-polizze",
      "testo": "Non ho altre coperture assicurative su questo rischio",
      "obbligatorio": true
    },
    {
      "chiave": "esigenza",
      "testo": "Desidero assicurarmi contro gli infortuni e per i danni subiti e provocati mentre svolgo attività sportiva dilettantistica",
      "obbligatorio": true
    },
    {
      "chiave": "etaAssicurati",
      "testo": "Gli assicurati hanno età compresa tra gli 8 e i 70 anni",
      "obbligatorio": true
    },
    {
      "chiave": "comunicazioni",
      "testo": "Desidero ricevere le comunicazioni via email",
      "obbligatorio": true
    }
  ],
  "campiPresaVisione": [
    {
      "chiave": "allegati",
      "testo": "Dichiaro di aver letto le informazioni di cui all'allegato 3,4 4-ter rilasciati dalla banca",
      "obbligatorio": true
    },
    {
      "chiave": "set-informativo",
      "testo": "Dichiaro di aver letto il Set informativo, comprensivo dell'informativa privacy e di accettare integralmente le condizioni di assicurazione",
      "obbligatorio": true
    }
  ],
  "esclusioni": [
    {
      "nome": "Eventi non riconducibili a incidenti"
    },
    {
      "nome": "Eventi fuori dagli impianti (fuoripista)"
    },
    {
      "nome": "Rischio valanga ignorato"
    },
    {
      "nome": "Sci Alpinismo"
    }
  ],
  "mailDenunciaSinistri": "claims@netinsurance.it",
  "indirizzoDenunciaSinistri": "Net Insurance Life S.p.A. c/o Casella Postale 106 - 26100 CREMONA – Italia (tramite lettera raccomandata A/R)"
}
