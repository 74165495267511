import { Rule, RuleObject } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import moment, { Moment } from "moment";
import { axiosScope } from "../config/axiosScope";
import { api } from "./api";

const verificaCodiceFiscale = api.validazione.verificaCodiceFiscale;

export type RuleObjectField = RuleObject & {
  field: string;
};

export const REQUIRED: Rule = { required: true, message: "Campo obbligatorio" };
export const CHECKED = (message = "Campo obbligatorio"): Rule => ({
  type: "boolean",
  required: true,
  validator: (_, value) => {
    if (/^([Tt][Rr][Uu][Ee])$/.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(!message ? "" : message);
    }
  },
});

export const CODICE_FISCALE =
  (nome: NamePath, cognome: NamePath): Rule =>
  ({ getFieldValue }) => ({
    async validator(_, value) {
      if (/^([A-Z]{6})(\d{2})([A-Z]{1})(\d{2})([A-Z]{1})([A-Z0-9]{3})([A-Z]{1})$/i.test(value)) {
        if (!getFieldValue(nome) || !getFieldValue(cognome)) {
          return Promise.reject("Compilare nome e cognome");
        } else {
          const { field } = _ as RuleObjectField;
          const { cancelToken = undefined } = axiosScope.get(`vi-${field}`, true);
          await verificaCodiceFiscale(
            {
              codiceFiscale: value,
              nome: getFieldValue(nome),
              cognome: getFieldValue(cognome),
            },
            { cancelToken }
          )
            .then(() => Promise.resolve())
            .catch(() => Promise.reject("Codice fiscale non valido"));
        }
      } else {
        return Promise.reject("Codice fiscale non valido");
      }
    },
  });

export const EMAIL: Rule = { type: "email", message: "Indirizzo email non valido" };
export const MAX_CHAR = (max: number): Rule => ({ type: "string", max, message: "Max. ${max} caratteri" });
export const MIN_CHAR = (min: number): Rule => ({ type: "string", min, message: "Almeno ${min} caratteri" });
export const CELL_PHONE: Rule = {
  pattern: /^(32[^15]|33[^20]|34\d|35[^2-46-9]|36[^45790]|37[^6]|38[^125-7]|39[^4-689])(\d){5,7}/,
  message: "Numero di cellulare non valido",
};
export const CAP: Rule = {
  pattern: /^\d{5}$/,
  message: "CAP non valido",
};
export const GIORNO_MESE_ANNO: Rule = {
  pattern:
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/,
  message: "Giorno/Mese/Anno non validi",
};
export const ORARIO: Rule = {
  pattern: /^([01]\d|2[0-3]):([0-5]\d)$/,
  message: "Orario non valido",
};
export const INTEGER: Rule = {
  type: "integer",
  message: "Solo numeri interi",
};
export const MIN = (min: number): Rule => ({ type: "number", min, message: "Min. ${min}" });
export const MAX = (max: number): Rule => ({ type: "number", max, message: "Max. ${max}" });

export const DATE: Rule = { type: "date", message: "Data non valida" };
export const NOT_BEFORE = (
  date: Moment | null,
  customConvertValue?: (date?: string) => string,
  message?: string
): Rule => {
  return {
    async validator(_, value) {
      value = customConvertValue ? moment(customConvertValue(value)).startOf("day") : value;
      const isValid = !date || (value && value.isSameOrAfter(date));
      return isValid ? Promise.resolve() : Promise.reject(new Error(`${message}`));
    },
  };
};

export const NOT_AFTER = (
  date: Moment | null,
  customConvertValue?: (date?: string) => string,
  message?: string
): Rule => {
  return {
    async validator(_, value) {
      value = customConvertValue ? moment(customConvertValue(value)).startOf("day") : value;
      const isValid = !date || (value && value.isSameOrBefore(date));
      return isValid ? Promise.resolve() : Promise.reject(new Error(`${message}`));
    },
  };
};
