export const APP_NAME = "Styla Care";
export const CUSTOMER_NAME = "Styla S.r.l.";
export const API_PATH = `${process.env.APP_BASE_PATH}`;
export const API_SERVER = process.env.DEV ? `${process.env.APP_BASE_PATH}` : `/`;

export const CHECKOUT_FINISH = "/checkout/finish";

export const NULL = "❓";
export const EMPTY = "-";
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_DAY_LONG = "dddd - DD/MM/YYYY";
export const DATE_FORMAT_DAY_SHORT = "ddd - DD/MM/YYYY";
export const DATE_FORMAT_DAY_YEAR_SHORT = "ddd DD/MM/YY";
export const DATE_FORMAT_SHORT = "DD/MM/YY";
export const DATE_FORMAT_YEAR = "YYYY";
export const TIME_FORMAT = "HH:mm";
export const DROP_DOWN_SIZE = 10;
export const MAX_PAGE_SIZE = 10000;
export const LOCALE = "it";
