import { Button, Col, Form, Row, Typography } from "antd";
import { Moment } from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSnapshot } from "valtio";
import DateField from "../../components/fields/DateField";
import SelectField from "../../components/fields/SelectField";
import Header from "../../components/Header";
import { DATE_FORMAT } from "../../config/constants";
import { FORM_GUTTER } from "../../config/layout";
import { Premio, Prodotto } from "../../generated/api";
import { momentToString, stringToMoment, todayDateMoment } from "../../shared/date-utils";
import { getIdPremioFromValue, numberToOption, numberToOptions, premiToOptions } from "../../shared/options-utilis";
import { DATE, NOT_BEFORE, REQUIRED } from "../../shared/validationRules";
import { prodottoState } from "../../store/prodotto/prodotto.state";
import { goToNextSteps, setStepData } from "../../store/steps/steps.actions";
import { stepsState } from "../../store/steps/steps.state";
import { Step } from "../../types/generics";
import { ISelectOption } from "../../types/ISelectOption";
import InputField from "../fields/InputField";
import InfoCard from "./parts/InfoCard";
import ProductCard from "./parts/ProductCard";

const currentStep = 2;
const { Title } = Typography;

interface FormRecord extends Omit<Step, "dataInizio" | "durata" | "persone"> {
  persone?: ISelectOption;
  dataInizio?: Moment | null;
  durata?: ISelectOption;
}

const formRecordToStep = (record: FormRecord): Step => {
  return {
    ...record,
    dataInizio: momentToString(record.dataInizio),
    durata: record?.durata,
    persone: record?.persone?.value as number,
  };
};

const stepToFormRecord = (step: Step): FormRecord => {
  const { dataInizio, durata, persone } = step;
  return {
    ...step,
    dataInizio: dataInizio ? stringToMoment(dataInizio) : undefined,
    durata: durata,
    persone: persone ? numberToOption(persone) : undefined,
  };
};

const Step2 = () => {
  const [form] = Form.useForm<FormRecord>();
  const { prodotto, loaded } = useSnapshot(prodottoState);
  const { data } = useSnapshot(stepsState);
  const { getFieldsValue, setFieldsValue, setFieldValue } = form;
  const persone = Form.useWatch("persone", form);
  const dataInizio = Form.useWatch("dataInizio", form);
  const durata = Form.useWatch("durata", form);

  const sortedPremi = useMemo(() => {
    if (loaded && prodotto) {
      const premi = prodotto.premi.map((p) => p);

      return premi.sort((a, b) => {
        return a.prezzo - b.prezzo;
      });
    }
  }, [prodotto, loaded]);

  const premio = useMemo(() => {
    const getPremioId = durata && getIdPremioFromValue(durata);

    return sortedPremi?.filter((p) => p.id === getPremioId)[0];
  }, [durata, sortedPremi]);

  useEffect(() => setFieldValue("idPremio", premio?.id), [premio, setFieldValue]);

  const saveStepValue = useCallback(() => {
    const values = getFieldsValue();
    const step = formRecordToStep(values);
    setStepData(step);
  }, [getFieldsValue]);

  const save = useCallback(() => {
    saveStepValue();
    goToNextSteps();
  }, [saveStepValue]);

  useEffect(() => {
    if (data) {
      setFieldsValue(stepToFormRecord(data));
    }
  }, [data, setFieldsValue]);

  return (
    <React.Fragment>
      <Header prevStep="Torna a Adeguatezza" saveStep={saveStepValue} />
      <div className="step">
        <Title className="fontWeightBold">{prodotto?.nome}</Title>
        <ProductCard prodotto={prodotto as Prodotto} prodottoLoaded={loaded} premio={sortedPremi && sortedPremi[0]} />
        <Form name={"step" + currentStep} form={form} layout="vertical" onFinish={save} scrollToFirstError={true}>
          <InputField name={"codiceProdotto"} initialValue={prodotto?.codice} hidden={true} readonly={true} />
          <InputField name={"idPremio"} hidden={true} />
          <Row gutter={FORM_GUTTER}>
            <Col span={24}>
              <SelectField
                name={"persone"}
                label="Quante persone vuoi assicurare?"
                options={numberToOptions(prodotto?.numeroMaxPersone)}
                rules={[REQUIRED]}
                isSearchable={false}
              />
            </Col>
          </Row>
          <Row gutter={FORM_GUTTER}>
            <Col span={24}>
              <DateField
                name={"dataInizio"}
                label={"Quando vuoi fare partire la polizza?"}
                rules={[REQUIRED, DATE, NOT_BEFORE(todayDateMoment, undefined, "Antecendete alla data di oggi")]}
                disableBefore={todayDateMoment}
                format={DATE_FORMAT}
              />
            </Col>
          </Row>
          <Row gutter={FORM_GUTTER}>
            <Col span={24}>
              <SelectField
                name={"durata"}
                label="Per quanti giorni vuoi assicurarti?"
                options={premiToOptions(sortedPremi as Premio[])}
                rules={[REQUIRED]}
                isSearchable={false}
              />
            </Col>
          </Row>
          <InfoCard durata={durata} persone={persone} dataInizio={dataInizio} premio={premio} />
          <Row gutter={FORM_GUTTER} className="center mt2 mb1">
            <Col span={24}>
              <Button htmlType="submit">Continua</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default Step2;
