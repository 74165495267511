import { Button, Typography } from "antd";
import parse from "html-react-parser";
import React, { useMemo } from "react";
import { useSnapshot } from "valtio";
import Header from "../../components/Header";
import Link from "../../components/Link";
import { CUSTOMER_NAME } from "../../config/constants";
import { DocumentoTipoEnum, Prodotto } from "../../generated/api";
import { prodottoState } from "../../store/prodotto/prodotto.state";
import { goToNextSteps } from "../../store/steps/steps.actions";

const { Title } = Typography;

export const getSetInformativo = (prodotto: Prodotto, loaded: boolean) => {
  if (loaded) {
    return prodotto?.documenti.filter((d) => d.tipo === DocumentoTipoEnum.SetInformativo)[0];
  }
};

const Step0 = () => {
  const { prodotto, loaded } = useSnapshot(prodottoState);

  const setInformativo = useMemo(
    () => (prodotto ? getSetInformativo(prodotto, loaded) : undefined),
    [prodotto, loaded]
  );

  const logoProdotto = prodotto?.logoProdotto ? prodotto?.logoProdotto : prodotto?.icona;

  return (
    <React.Fragment>
      <Header showLogo={true} />
      <div className="step step_0 center">
        <Title>Benvenuto !</Title>
        <div className="prodotto">
          <img src={logoProdotto} alt={CUSTOMER_NAME} width={110} className={"mt1 mb1"} />
          <Title level={2} className="fontWeightBold">
            {prodotto?.nome}
          </Title>
          <p className="text-size text-size--large">{prodotto?.descrizione && parse(prodotto.descrizione)}</p>
          <p>
            <Link type="a" path={setInformativo?.path} label={setInformativo?.nome} />
          </p>
          <Button className="mt2" onClick={goToNextSteps}>
            Inizia
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Step0;
