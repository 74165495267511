import { loadStripe } from "@stripe/stripe-js";
import { notification } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import * as React from "react";
import { notificationConfig } from "../config/notificationConfig";
import { Problem } from "../generated/api";

export const formatErrorDescription = (error: Error | AxiosError<Problem>, explanation?: React.ReactNode) => {
  const description: React.ReactNode = explanation ?? error.message ?? String(error);
  if (axios.isAxiosError(error)) {
    const response = error.response as AxiosResponse<Problem>;
    const status = response?.status;
    const detail = response?.data?.detail ?? response?.data?.title ?? "";
    const codice = response?.data?.instance?.split("/").slice(-1)[0]; // Ultima parte
    if (detail && status && status >= 400 && status <= 500) {
      return (
        <>
          <div>
            {status} - {detail}
          </div>
          {codice && <div>Codice errore: {codice}</div>}
        </>
      );
    }
  }
  return description;
};

export const catchErrorHandlerApi = (error: Error | AxiosError<Problem>) => {
  if (!axios.isCancel(error)) {
    console.error("Caught error", error);

    const description = formatErrorDescription(error);
    notification.error(notificationConfig("Errore", description));
  }
};

export const goToPayment = (publicKey: string, sessionId: string) => {
  const stripe = loadStripe(publicKey);

  return stripe.then((response) => response?.redirectToCheckout({ sessionId: sessionId }));
};
