import { ConfigProvider } from "antd";
import itIT from "antd/lib/locale/it_IT";
import moment from "moment";
import "moment/locale/it";
import React from "react";
import "./App.less";
import ErrorBoundary from "./components/ErrorBoundary";
import { LOCALE } from "./config/constants";
import { AppRoutes } from "./Routes";

moment.locale(LOCALE);

export const App: React.FC = () => (
  <ErrorBoundary>
    <ConfigProvider locale={itIT}>
      <AppRoutes />
    </ConfigProvider>
  </ErrorBoundary>
);
