'use strict';

var ReactExports = require('react');
var proxyCompare = require('proxy-compare');
var useSyncExternalStoreExports = require('use-sync-external-store/shim');
var vanilla = require('valtio/vanilla');

var use = ReactExports.use;
var useSyncExternalStore = useSyncExternalStoreExports.useSyncExternalStore;

var affectedToPathList = function affectedToPathList(obj, affected) {
  var list = [];
  var seen = new WeakSet();
  var walk = function walk(x, path) {
    if (seen.has(x)) {
      return;
    }
    var used;
    if (typeof x === 'object' && x !== null) {
      seen.add(x);
      used = affected.get(proxyCompare.getUntracked(x) || x);
    }
    if (used) {
      used.forEach(function (key) {
        if ('value' in (Object.getOwnPropertyDescriptor(x, key) || {})) {
          walk(x[key], path ? [].concat(path, [key]) : [key]);
        }
      });
    } else if (path) {
      list.push(path);
    }
  };
  walk(obj);
  return list;
};
var useAffectedDebugValue = function useAffectedDebugValue(state, affected) {
  var pathList = ReactExports.useRef();
  ReactExports.useEffect(function () {
    pathList.current = affectedToPathList(state, affected);
  });
  ReactExports.useDebugValue(pathList.current);
};
function useSnapshot(proxyObject, options) {
  var notifyInSync = options == null ? void 0 : options.sync;
  var lastSnapshot = ReactExports.useRef();
  var lastAffected = ReactExports.useRef();
  var inRender = true;
  var currSnapshot = useSyncExternalStore(ReactExports.useCallback(function (callback) {
    var unsub = vanilla.subscribe(proxyObject, callback, notifyInSync);
    callback();
    return unsub;
  }, [proxyObject, notifyInSync]), function () {
    var nextSnapshot = vanilla.snapshot(proxyObject, use);
    try {
      if (!inRender && lastSnapshot.current && lastAffected.current && !proxyCompare.isChanged(lastSnapshot.current, nextSnapshot, lastAffected.current, new WeakMap())) {
        return lastSnapshot.current;
      }
    } catch (e) {
    }

    return nextSnapshot;
  }, function () {
    return vanilla.snapshot(proxyObject, use);
  });
  inRender = false;
  var currAffected = new WeakMap();
  ReactExports.useEffect(function () {
    lastSnapshot.current = currSnapshot;
    lastAffected.current = currAffected;
  });
  if (process.env.NODE_ENV !== "production") {
    useAffectedDebugValue(currSnapshot, currAffected);
  }
  var proxyCache = ReactExports.useMemo(function () {
    return new WeakMap();
  }, []);
  return proxyCompare.createProxy(currSnapshot, currAffected, proxyCache);
}

Object.defineProperty(exports, 'getVersion', {
  enumerable: true,
  get: function () { return vanilla.getVersion; }
});
Object.defineProperty(exports, 'proxy', {
  enumerable: true,
  get: function () { return vanilla.proxy; }
});
Object.defineProperty(exports, 'ref', {
  enumerable: true,
  get: function () { return vanilla.ref; }
});
Object.defineProperty(exports, 'snapshot', {
  enumerable: true,
  get: function () { return vanilla.snapshot; }
});
Object.defineProperty(exports, 'subscribe', {
  enumerable: true,
  get: function () { return vanilla.subscribe; }
});
Object.defineProperty(exports, 'unstable_buildProxyFunction', {
  enumerable: true,
  get: function () { return vanilla.unstable_buildProxyFunction; }
});
exports.useSnapshot = useSnapshot;
