import { LoadOptionsType } from "../components/fields/controls/SelectAsync";
import { axiosScope } from "../config/axiosScope";
import { Comune, Premio, PremioUnitaDurataEnum } from "../generated/api";
import { ISelectOption } from "../types/ISelectOption";
import { api } from "./api";
import { FiltroApiCall } from "./api-generics";
import { numberToPrice } from "./currency-utilis";

export type MapModelToOption<T> = (record: T) => ISelectOption;
export type MapModelToOptions<T> = (record: T[]) => ISelectOption[];

export const getUnitaDurata = (unita?: PremioUnitaDurataEnum) => {
  switch (unita) {
    case PremioUnitaDurataEnum.Giorno:
      return 1;
    case PremioUnitaDurataEnum.Settimana:
      return 7;
    case PremioUnitaDurataEnum.Mese:
      return 30;
    case PremioUnitaDurataEnum.Anno:
      return 365;
  }
};

export const numberToOption = (value?: number) => ({
  value: value,
  label: value?.toString(),
});

export const comuneToOption: MapModelToOption<Comune> = (comune) => ({
  value: comune.id,
  label: comune.nome,
});

export const numberToOptions = (end?: number, start = 1) => {
  if (!end) {
    return [];
  }
  const options = [];
  for (let i = start; i <= end; i++) {
    options.push({
      value: i,
      label: i.toString(),
    } as ISelectOption);
  }
  return options;
};

export const premioToLabel = (nome: string, durata: number, valore?: number, unitaDurata?: PremioUnitaDurataEnum) => {
  const labelGiorni = unitaDurata !== PremioUnitaDurataEnum.Giorno || (durata && durata > 1) ? "Giorni" : "Giorno";

  return `${(getUnitaDurata(unitaDurata) as number) * durata} ${labelGiorni} a ${numberToPrice(
    valore && valore * durata
  )} per persona assicurata`;
};

export const getIdPremioValuePremio = (id: number, durata: number) => `${id}-${durata}`;
export const getIdPremioFromValue = (data: ISelectOption) =>
  data?.value ? Number((data.value as string).split("-")[0]) : 0;
export const getValueFromValueIdPremioValuePremio = (data: ISelectOption) =>
  data?.value ? Number((data.value as string).split("-")[1]) : 0;

export const premioToOption = (premio?: Premio, durata?: number) => ({
  value: getIdPremioValuePremio(premio?.id ?? 0, durata ?? 0),
  label: premioToLabel(premio?.nome ?? "", durata ?? 0, premio?.prezzo, premio?.unitaDurata),
});

export const premiToOptions = (premi: Premio[]) => {
  const options = [] as ISelectOption[];

  premi.forEach((premio) => {
    if ((premio.durataMax as number) > 1) {
      for (let i = premio.durataMin as number; i <= (premio.durataMax ?? 0); i++) {
        // options.push({
        //   // value: getIdPremioValuePremio(premio.id, (getUnitaDurata(premio?.unitaDurata) as number) * i),
        //   value: getIdPremioValuePremio(premio.id, i),
        //   label: premioToLabel(premio.nome, i ?? 0, premio.unitaDurata),
        // } as ISelectOption);
        options.push(premioToOption(premio, i) as ISelectOption);
      }
    } else {
      options.push(premioToOption(premio, premio.durataMax ?? 0) as ISelectOption);
      // options.push({
      //   // value: getIdPremioValuePremio(premio.id, getUnitaDurata(premio?.unitaDurata) as number),
      //   value: getIdPremioValuePremio(premio.id, premio?.durataMax ?? 0),
      //   label: premioToLabel(premio.nome, premio.durataMax ?? 0, premio.unitaDurata),
      // } as ISelectOption);
    }
  });

  return options;
};

// Factory function for creating "MapModelToOptions" functions
export function mapToOptionsFactory<T>(mapper: MapModelToOption<T>): MapModelToOptions<T> {
  return (records) => records.map(mapper);
}

export const comuniToOptions = mapToOptionsFactory<Comune>(comuneToOption);

// Factory function for creating "LoadOptions" functions (with pagination).
// To be used as prop "loadOptions" into "SelectAsyncField" component.
function loadOptionsFactory<T extends object>(
  scope: string,
  apiFiltro: FiltroApiCall<T>,
  mapper: MapModelToOption<T>
): LoadOptionsType {
  // return (search, _loadedOptions, additional) => {
  return (search) => {
    const { cancelToken = undefined } = axiosScope.get(scope, true);

    return apiFiltro(search, { cancelToken }).then((response) => {
      const records: T[] = response.data || [];
      // const headers = response.headers;
      // const pageNumber = Number.parseInt(headers["x-page-number"]);
      // const totalCount = Number.parseInt(headers["x-total-count"]);
      // const hasMore = (pageNumber + 1) * DROP_DOWN_SIZE < totalCount;
      return {
        options: records.map(mapper),
        // hasMore,
        // additional: { page: pageNumber + 1 },
      };
    });
  };
}

export const loadOptionsComuni = loadOptionsFactory<Comune>("lo-filtroComuni", api.localita.getComuni, comuneToOption);
