import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { booleanString } from "./lib/booleanString";

if (booleanString(process.env.USE_MOCK_API)) {
  /* eslint @typescript-eslint/no-var-requires: "off" */
  const { worker } = require("./mocks/browser");
  worker.start();
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />) as React.ReactNode;
