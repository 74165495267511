import { Button, Col, Form, Row, Typography } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import AppLoading from "../components/fields/AppLoading";
import Header from "../components/Header";
import InfoAltriAssicurati from "../components/steps/parts/InfoAltriAssicurati";
import InfoCard from "../components/steps/parts/InfoCard";
import InfoContraente from "../components/steps/parts/InfoContraente";
import ProductCard from "../components/steps/parts/ProductCard";
import { FormRecord } from "../components/steps/Step3";
import { FORM_GUTTER } from "../config/layout";
import { Proposta as PropostaType, RichiestaQuotazione } from "../generated/api";
import { api } from "../shared/api";
import { catchErrorHandlerApi } from "../shared/api-utilis";
import { mmGgYyyyToFormatted, stringToMoment } from "../shared/date-utils";
import { goToTop } from "../shared/goToTop";
import {
  comuneToOption,
  getValueFromValueIdPremioValuePremio,
  numberToOption,
  premioToOption,
} from "../shared/options-utilis";
import { getPathPagamento, getPathProdotto, getPathProposta } from "../shared/path-utils";
import { loadProdottoAction } from "../store/prodotto/prodotto.actions";
import { prodottoState } from "../store/prodotto/prodotto.state";
import { setProposta } from "../store/proposta/proposta.actions";
import { propostaState } from "../store/proposta/proposta.state";
import { stepsState } from "../store/steps/steps.state";
import { ISelectOption } from "../types/ISelectOption";

const creaProposta = api.proposta.creaProposta;
const recuperaProposta = api.proposta.recuperaProposta;

const { Title } = Typography;

const stepToFormRecord = (step: PropostaType): FormRecord => {
  const { contraente } = step;
  return {
    ...step,
    durata: step.durata ? premioToOption(step.dettaglioPremio, step.durata) : undefined,
    contraente: {
      ...contraente,
      dataDiNascita: contraente?.dataDiNascita ? mmGgYyyyToFormatted(contraente.dataDiNascita) : undefined,
      comune: contraente?.comune ? comuneToOption(contraente?.comune) : undefined,
      comuneDiNascita: contraente?.comuneDiNascita ? comuneToOption(contraente?.comuneDiNascita) : undefined,
    },
  };
};

const stepToRichiestaQuotazione = (step: RichiestaQuotazione): RichiestaQuotazione => {
  return {
    ...step,
    durata: step.durata ? getValueFromValueIdPremioValuePremio(step.durata as ISelectOption) : 0,
  };
};

export const Proposta: React.FC = () => {
  const [form] = Form.useForm<FormRecord>();
  const { code, propostaId } = useParams();
  const { data } = useSnapshot(stepsState);
  const { prodotto, loaded } = useSnapshot(prodottoState);
  const { proposta } = useSnapshot(propostaState);
  const { step } = useSnapshot(stepsState);
  const navigate = useNavigate();

  const { setFieldsValue } = form;

  const numeroAltriAssicurati = proposta ? proposta.altriAssicurati.length : 0;
  const numeroPersoneAssicurate = numeroAltriAssicurati + (proposta && proposta.contraente.assicurato ? 1 : 0);
  const backTo = useMemo(() => (step === 0 ? "Crea una nuova proposta" : "Torna a privacy"), [step]);

  const save = useCallback(() => {
    if (proposta && code) {
      navigate(getPathPagamento(code ?? "", proposta.id));
    }
  }, [proposta, code, navigate]);

  useEffect(() => {
    if (!data) {
      loadProdottoAction(code ?? "");
    }
  }, [code, data]);

  useEffect(() => {
    goToTop();
    if (data && !propostaId && code) {
      const formData = stepToRichiestaQuotazione(data as RichiestaQuotazione);
      creaProposta(formData)
        .then((response) => {
          setProposta(response.data);
          setFieldsValue(stepToFormRecord(response.data));
          navigate(getPathProposta(code, response.data.id), { replace: true });
        })
        .catch((error) => {
          catchErrorHandlerApi(error);
          navigate(-1);
        });
    } else {
      recuperaProposta(propostaId ?? "")
        .then((response) => {
          setProposta(response.data);
          setFieldsValue(stepToFormRecord(response.data));
        })
        .catch((error) => {
          catchErrorHandlerApi(error);
          navigate(-1);
        });
    }
  }, [data, propostaId, code, navigate, setFieldsValue]);

  return !loaded && !prodotto && !proposta?.id ? (
    <AppLoading />
  ) : (
    <React.Fragment>
      <Header goTo={backTo} navigateTo={getPathProdotto(prodotto?.codice ?? "")} />
      <div className="proposta">
        <Title className="fontWeightBold">Riepilogo</Title>
        <Form name={"proposta"} form={form} layout="vertical" onFinish={save} scrollToFirstError={true}>
          <ProductCard
            prodotto={prodotto}
            prodottoLoaded={loaded}
            premio={proposta?.dettaglioPremio}
            showTitle={true}
            isProposta={true}
          />
          <InfoCard
            durata={premioToOption(proposta?.dettaglioPremio, proposta?.durata)}
            dataInizio={stringToMoment(proposta?.dataInizio)}
            premio={proposta?.dettaglioPremio}
            persone={numberToOption(numeroPersoneAssicurate)}
          />
          <Row gutter={FORM_GUTTER} className="mt2">
            <Col span={24}>
              <Title className="fontWeightBold">Dati assicurati</Title>
            </Col>
          </Row>
          <InfoContraente readonly={true} contraenteAssicurato={proposta?.contraente?.assicurato} />
          <InfoAltriAssicurati numeroAltriAssicurati={numeroAltriAssicurati} readonly={true} />
          <Row gutter={FORM_GUTTER} className="center mt2 mb1">
            <Col span={24}>
              <Button htmlType="submit">Val al pagamento</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};
