import { Proposta } from "../../generated/api";
import { propostaState } from "./proposta.state";

export const resetPropotto = () => {
  propostaState.proposta = null;
};

export const setLoadedPropotto = () => {
  propostaState.loaded = true;
};

export const setUnloadedPropotto = () => {
  propostaState.loaded = false;
};

export const setProposta = (data: Proposta) => {
  propostaState.proposta = data;
};
