import { Form, FormItemProps, Switch } from "antd";
import React, { useMemo } from "react";
import ReadOnlyBoolean from "./controls/ReadOnlyBoolean";

interface IProps extends FormItemProps {
  checkedLabel?: string;
  readonly?: boolean;
  unCheckedLabel?: string;
}

const BooleanField: React.FC<IProps> = ({ checkedLabel = "Sì", readonly = false, unCheckedLabel = "No", ...rest }) => {
  const customLabel = useMemo(
    () =>
      rest.required ? (
        <div className="customLabel">
          <div className="label">{rest.label}</div>{" "}
          <div className="error">
            <small>Campo obbligatorio</small>
          </div>
        </div>
      ) : (
        <div className="customLabel">
          <div className="label">{rest.label}</div>
        </div>
      ),
    [rest]
  );
  return (
    <Form.Item
      {...rest}
      valuePropName="checked"
      className={"boolean-field " + rest.className + (readonly && " readonly")}
      label={customLabel}
    >
      {readonly ? (
        <ReadOnlyBoolean checkedLabel={checkedLabel} unCheckedLabel={unCheckedLabel} />
      ) : (
        <Switch checkedChildren={checkedLabel} unCheckedChildren={unCheckedLabel} />
      )}
    </Form.Item>
  );
};

export default BooleanField;
