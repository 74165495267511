import { Form, FormItemProps, InputNumber, InputNumberProps } from "antd";
import React, { Ref } from "react";
import ReadOnly from "./controls/ReadOnly";

interface IProps extends FormItemProps {
  autoFocus?: boolean;
  controls?: InputNumberProps["controls"];
  disabled?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  onBlur?: InputNumberProps["onBlur"];
  readonly?: boolean;
  unit?: string;
}

const NumberField: React.FC<IProps> = ({
  autoFocus,
  controls,
  disabled,
  inputRef,
  onBlur,
  readonly = false,
  unit,
  ...rest
}) => {
  return (
    <Form.Item {...rest}>
      {readonly ? (
        <ReadOnly unit={unit} />
      ) : (
        <InputNumber
          addonAfter={unit}
          disabled={disabled}
          ref={inputRef}
          autoFocus={autoFocus}
          controls={controls}
          style={{ width: "100%" }}
          onBlur={onBlur}
        />
      )}
    </Form.Item>
  );
};

export default NumberField;
