import React from "react";

interface IProps {
  value?: string | number;
  unit?: string;
}

const ReadOnly: React.FC<IProps> = ({ value, unit }) => {
  return (
    <div>
      {value}
      {unit ? ` ${unit}` : ""}
    </div>
  );
};

export default ReadOnly;
