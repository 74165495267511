import { Form, FormItemProps } from "antd";
import React from "react";
import InputMask from "react-input-mask";
import ReadOnly from "./controls/ReadOnly";

interface IProps extends FormItemProps {
  mask: string;
  placeholder?: string;
  readonly?: boolean;
  inputMode?: string;
  pattern?: RegExp;
}

const MaskField: React.FC<IProps> = ({ mask, placeholder, readonly = false, pattern, inputMode, ...rest }) => {
  return (
    <Form.Item {...rest}>
      {readonly ? (
        <ReadOnly />
      ) : (
        <InputMask
          type="text"
          mask={mask}
          placeholder={placeholder}
          className="ant-input"
          pattern={pattern}
          inputMode={inputMode}
        />
      )}
    </Form.Item>
  );
};

export default MaskField;
