import { StylesConfig } from "react-select/dist/declarations/src/styles";
import { COLOR_ORANGE, COLOR_RED, COLOR_VIOLA, COLOR_VIOLA_LIGHT, COLOR_VIOLA_MEDIUM } from "../../../config/layout";
import { ISelectOption } from "../../../types/ISelectOption";

const selectedColor = COLOR_VIOLA_MEDIUM;
const hoverColor = COLOR_VIOLA_LIGHT;
const optionColor = COLOR_VIOLA;

// @orange: #ff4a1a;
// @orangeDark: darken(#ff4a1a, 10);
// @viola: #47082c;

export const selectAsyncCustomStyle: StylesConfig<ISelectOption> = {
  singleValue: (style, { data, options }) => {
    const isDisabled = Boolean((options as ISelectOption[]).find((o) => o.value === data.value)?.isDisabled);
    return { ...style, color: isDisabled ? COLOR_RED : optionColor };
  },
  multiValueLabel: (style, { data, options }) => {
    const isDisabled = Boolean((options as ISelectOption[]).find((o) => o.value === data.value)?.isDisabled);
    return { ...style, color: isDisabled ? COLOR_RED : optionColor };
  },
  // https://react-select.com/styles
  control: (style) => ({
    ...style,
    borderRadius: "10px",
    minHeight: "40px",
  }),
  clearIndicator: (style) => ({
    ...style,
    ":hover": { color: COLOR_ORANGE },
    padding: "4px",
  }),
  dropdownIndicator: (style) => ({
    ...style,
    padding: "4px 10px",
    color: COLOR_ORANGE,
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: "none",
  }),
  valueContainer: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: "22px",
  }),
  option: (style, state) => ({
    ...style,
    color: state.isSelected ? "black" : optionColor,
    backgroundColor: state.isSelected ? selectedColor : "white",
    opacity: state.isDisabled ? 0.5 : 1,
    ":hover": {
      cursor: "pointer",
      backgroundColor: state.isSelected ? selectedColor : hoverColor,
    },
  }),
  menu: (style) => ({
    ...style,
    borderRadius: 0,
  }),
};
