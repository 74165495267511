import { proxy } from "valtio";
import { Proposta } from "../../generated/api";

export interface PropostaState {
  loaded: boolean;
  proposta: Proposta | null;
}

export const propostaState: PropostaState = proxy<PropostaState>({
  loaded: false,
  proposta: null,
});
