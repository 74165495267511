import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import AppLoading from "../components/fields/AppLoading";
import Step0 from "../components/steps/Step0";
import Step1 from "../components/steps/Step1";
import Step2 from "../components/steps/Step2";
import Step3 from "../components/steps/Step3";
import Step4 from "../components/steps/Step4";
import { goToTop } from "../shared/goToTop";
import { loadProdottoAction } from "../store/prodotto/prodotto.actions";
import { prodottoState } from "../store/prodotto/prodotto.state";
import { stepsState } from "../store/steps/steps.state";

export const Steps: React.FC = () => {
  const { code } = useParams();
  const { prodotto, loaded } = useSnapshot(prodottoState);
  const { data, step } = useSnapshot(stepsState);

  useEffect(() => {
    if (!data) {
      loadProdottoAction(code ?? "");
    }
  }, [code, data]);

  const CurrentStep = useMemo(() => {
    goToTop();
    switch (step) {
      case 0:
        return Step0;
      case 1:
        return Step1;
      case 2:
        return Step2;
      case 3:
        return Step3;
      case 4:
        return Step4;
      default:
        return Step0;
    }
  }, [step]);

  return !loaded ? <AppLoading /> : <React.Fragment>{prodotto ? <CurrentStep /> : null}</React.Fragment>;
};
