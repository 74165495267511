import React from "react";
import { GroupBase } from "react-select";
import { AsyncPaginate, AsyncPaginateProps } from "react-select-async-paginate";
import { LoadOptions } from "react-select-async-paginate/ts/types";
import { ISelectOption } from "../../../types/ISelectOption";
import { selectAsyncCustomStyle } from "./selectAsyncCustomStyle";

export type Additional = { page?: number };

export type LoadOptionsType = LoadOptions<ISelectOption, GroupBase<ISelectOption>, Additional>;

interface IProps<IsMulti extends boolean>
  extends AsyncPaginateProps<ISelectOption, GroupBase<ISelectOption>, Additional, IsMulti> {
  loadOptions: LoadOptionsType;
}

function SelectAsync<IsMulti extends boolean>({ isMulti, loadOptions, ...rest }: IProps<IsMulti>) {
  return (
    <React.Fragment>
      <AsyncPaginate
        isClearable
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        getOptionValue={(option) => String(option.value)}
        loadOptions={loadOptions}
        styles={selectAsyncCustomStyle}
        {...rest}
      />
    </React.Fragment>
  );
}

export default SelectAsync;
