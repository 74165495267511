import { proxy } from "valtio";
import { Proposta } from "../../generated/api";
import { Step } from "../../types/generics";

export interface StepsState {
  step: number;
  data: Step | null;
  proposta: Proposta | null;
}

export const stepsState: StepsState = proxy<StepsState>({
  step: 0,
  data: null,
  proposta: null,
});
