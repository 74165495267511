import React from "react";
import { Link as ReactLink } from "react-router-dom";

type IProps = {
  type: "a" | "react";
  path?: string;
  target?: string;
  label?: string;
  size?: "default" | "small";
  icon?: React.ReactNode;
};

const Link: React.FC<IProps> = ({ type = "react", path, target = "_blank", label, size = "default", icon }) => {
  if (type === "react") {
    return (
      <ReactLink to={{ pathname: path ?? "" }} target={target} className={"link " + size}>
        {icon} {label ?? ""}
      </ReactLink>
    );
  } else {
    return (
      <a href={path ?? ""} target={target} className={"link " + size}>
        {icon} {label ?? ""}
      </a>
    );
  }
};

export default Link;
