import { Button, Col, Form, Row, Typography } from "antd";
import React, { useCallback, useEffect } from "react";
import { useSnapshot } from "valtio";
import BooleanField from "../../components/fields/BooleanField";
import Header from "../../components/Header";
import { FORM_GUTTER, LOGO_SIZE_SMALL } from "../../config/layout";
import { CHECKED } from "../../shared/validationRules";
import { prodottoState } from "../../store/prodotto/prodotto.state";
import { goToNextSteps, setStepData } from "../../store/steps/steps.actions";
import { stepsState } from "../../store/steps/steps.state";
import { Step } from "../../types/generics";

const currentStep = 1;
const { Title } = Typography;

interface FormRecord extends Step {}

const Step1 = () => {
  const [form] = Form.useForm<FormRecord>();
  const { prodotto } = useSnapshot(prodottoState);
  const { data } = useSnapshot(stepsState);
  const { getFieldsValue, setFieldsValue } = form;

  const save = useCallback(() => {
    const values = getFieldsValue();
    setStepData(values);
    goToNextSteps();
  }, [getFieldsValue]);

  useEffect(() => {
    if (data) {
      setFieldsValue(data);
    }
  }, [data, setFieldsValue]);

  return (
    <React.Fragment>
      <Header showLogo={true} sizeLogo={LOGO_SIZE_SMALL} />
      <div className="step step_1">
        <Title className="fontWeightBold">Adeguatezza</Title>
        <Form name={"step" + currentStep} form={form} layout="vertical" onFinish={save} scrollToFirstError={true}>
          <Row className="mb1">
            <Col span={24}>
              <small className="text-color text-color--grey">Tutti i campi sono richiesti</small>
            </Col>
          </Row>
          {prodotto?.campiAdeguatezza.map((field, index) => (
            <Row gutter={FORM_GUTTER} key={index}>
              <Col span={24}>
                <BooleanField
                  name={["campiAdeguatezza", field.chiave]}
                  label={field.testo}
                  rules={[field.obbligatorio ? CHECKED("") : {}]}
                  required={field.obbligatorio}
                  initialValue={false}
                />
              </Col>
            </Row>
          ))}
          <Row gutter={FORM_GUTTER} className="center mt2 mb1">
            <Col span={24}>
              <Button htmlType="submit">Continua</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default Step1;
