import { Divider } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import moment from "moment";
import React from "react";
import { useSnapshot } from "valtio";
import logoStylaCare from "../assets/svg/logoStylaCare.svg";
import { CUSTOMER_NAME, DATE_FORMAT_YEAR } from "../config/constants";
import { prodottoState } from "../store/prodotto/prodotto.state";

const year = moment().format(DATE_FORMAT_YEAR);

export const StylaFooter: React.FC = () => {
  const { prodotto, loaded } = useSnapshot(prodottoState);
  return (
    <Footer className="footer">
      <Content>
        {loaded && (
          <>
            <a href={prodotto?.compagnia.url} rel="noreferrer" target="_blank">
              <img src={prodotto?.compagnia.logo} width={59} alt={prodotto?.compagnia.nome} className="mb05" />
            </a>
            <p>Prodotto in collaborazione con {prodotto?.compagnia.nome}</p>
          </>
        )}
        <Divider className="my05" />
        <p className="info">
          © {year} STYLA S.R.L. Via Giovanna Murari Bra 49/c - 37136 Verona RUI B000684872 • P.IVA 04813360239 •{" "}
          <a href="mailto:info@styla.care">info@styla.care</a>
        </p>
        <div>
          <img src={logoStylaCare} alt={CUSTOMER_NAME} width={82} className="centerBlock" />
        </div>
      </Content>
    </Footer>
  );
};
