import { Col, Divider, Row, Typography } from "antd";
import { useMemo } from "react";
import { FORM_GUTTER } from "../../../config/layout";
import { CODICE_FISCALE, REQUIRED } from "../../../shared/validationRules";
import InputField from "../../fields/InputField";

const { Title } = Typography;

interface IProps {
  numeroAltriAssicurati: number;
  readonly?: boolean;
}

const InfoAltriAssicurati: React.FC<IProps> = ({ numeroAltriAssicurati, readonly = false }) => {
  const getAltriAssicurati = useMemo(() => {
    const content = [];
    for (let index = 0; index < numeroAltriAssicurati; index++) {
      content.push(
        <div key={index}>
          <Row gutter={FORM_GUTTER}>
            <Divider />
          </Row>
          <Row gutter={FORM_GUTTER}>
            <Col span={24}>
              <Title level={5} className="fontWeightBold">
                Altro assicurato {index + 1}
              </Title>
            </Col>
          </Row>
          <Row gutter={FORM_GUTTER}>
            <Col span={24}>
              <InputField
                name={["altriAssicurati", index, "nome"]}
                label="Nome"
                rules={[REQUIRED]}
                readonly={readonly}
              />
            </Col>
          </Row>
          <Row gutter={FORM_GUTTER}>
            <Col span={24}>
              <InputField
                name={["altriAssicurati", index, "cognome"]}
                label="Cognome"
                rules={[REQUIRED]}
                readonly={readonly}
              />
            </Col>
          </Row>
          <Row gutter={FORM_GUTTER}>
            <Col span={24}>
              <InputField
                name={["altriAssicurati", index, "codiceFiscale"]}
                label="Codice fiscale"
                rules={[
                  REQUIRED,
                  CODICE_FISCALE(["altriAssicurati", index, "nome"], ["altriAssicurati", index, "cognome"]),
                ]}
                className="uppercase"
                readonly={readonly}
              />
            </Col>
          </Row>
        </div>
      );
    }
    return content;
  }, [numeroAltriAssicurati, readonly]);
  return <div>{getAltriAssicurati}</div>;
};

export default InfoAltriAssicurati;
