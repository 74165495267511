import { Button, Col, Divider, Form, Row, Typography } from "antd";
import React, { useCallback, useEffect } from "react";
import { FiFileText } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import Header from "../../components/Header";
import Link from "../../components/Link";
import { FORM_GUTTER } from "../../config/layout";
import { PROPOSTA_PAGE } from "../../config/paths";
import { CHECKED } from "../../shared/validationRules";
import { prodottoState } from "../../store/prodotto/prodotto.state";
import { setStepData } from "../../store/steps/steps.actions";
import { stepsState } from "../../store/steps/steps.state";
import { Step } from "../../types/generics";
import BooleanField from "../fields/BooleanField";
import WrapIcon from "../WrapIcon";

const currentStep = 4;
const { Title } = Typography;

interface FormRecord extends Step {}

const Step4 = () => {
  const [form] = Form.useForm<FormRecord>();
  const { prodotto } = useSnapshot(prodottoState);
  const { data } = useSnapshot(stepsState);
  const { getFieldsValue, setFieldsValue } = form;
  const navigate = useNavigate();

  const saveStepValue = useCallback(() => {
    const values = getFieldsValue();
    setStepData(values);
  }, [getFieldsValue]);

  const save = useCallback(() => {
    saveStepValue();
    navigate(`.${PROPOSTA_PAGE}`);
  }, [saveStepValue, navigate]);

  useEffect(() => {
    if (data) {
      setFieldsValue(data);
    }
  }, [data, setFieldsValue]);

  return (
    <React.Fragment>
      <Header prevStep="Torna a dati assicurati" saveStep={saveStepValue} />
      <div className="step">
        <Title className="fontWeightBold">Privacy</Title>
        <p>
          Per poter prosequire, occorre leggere e accettare la sequente informativa precontrattuale e rispondere alle
          domande.
        </p>
        <Row>
          <Col span={24}>
            {prodotto?.documenti.map((documento, index) => (
              <p key={index}>
                <Link
                  type="a"
                  path={documento?.path ? documento.path : ""}
                  label={documento?.nome}
                  icon={<WrapIcon Icon={FiFileText} />}
                />
              </p>
            ))}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <Title level={5} className="fontWeightBold">
              Dichiaro di aver preso visione della documentazione sopra elencata.
            </Title>
          </Col>
        </Row>
        <Row className="mb1">
          <Col span={24}>
            <small className="text-color text-color--grey">Tutti i campi sono richiesti</small>
          </Col>
        </Row>
        <Form name={"step" + currentStep} form={form} layout="vertical" onFinish={save} scrollToFirstError={true}>
          {prodotto?.campiPresaVisione.map((field, index) => (
            <Row gutter={FORM_GUTTER} key={index}>
              <Col span={24}>
                <BooleanField
                  name={["campiPresaVisione", field.chiave]}
                  label={field.testo}
                  rules={[field.obbligatorio ? CHECKED("") : {}]}
                  required={field.obbligatorio}
                  initialValue={false}
                />
              </Col>
            </Row>
          ))}
          <Row gutter={FORM_GUTTER} className="center mt2 mb1">
            <Col span={24}>
              <Button htmlType="submit">Vai al riepilogo</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default Step4;
