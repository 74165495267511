import { Button, Col, Row, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import ko from "../../assets/svg/ko.svg";
import AppLoading from "../../components/fields/AppLoading";
import Header from "../../components/Header";
import { LOGO_SIZE_SMALL } from "../../config/layout";
import { InfoPagamento, Proposta } from "../../generated/api";
import { api } from "../../shared/api";
import { catchErrorHandlerApi, goToPayment } from "../../shared/api-utilis";
import { loadProdottoAction } from "../../store/prodotto/prodotto.actions";
import { prodottoState } from "../../store/prodotto/prodotto.state";

const confermaProposta = api.proposta.confermaProposta;
const recuperaProposta = api.proposta.recuperaProposta;

const { Title } = Typography;

const StatusKo: React.FC = () => {
  const { propostaId } = useParams();
  const { prodotto } = useSnapshot(prodottoState);
  const [proposta, setProposta] = useState<Proposta>();
  const [pagamento, setPagamento] = useState<InfoPagamento>();

  useEffect(() => {
    if (!prodotto && proposta) {
      loadProdottoAction(proposta?.codiceProdotto ?? "");
    }
  }, [proposta, prodotto]);

  useEffect(() => {
    if (propostaId) {
      recuperaProposta(propostaId)
        .then((response) => {
          setProposta(response.data);
        })
        .catch(catchErrorHandlerApi);
      confermaProposta(propostaId)
        .then((response) => {
          setPagamento(response.data);
        })
        .catch(catchErrorHandlerApi);
    }
  }, [propostaId]);

  const payment = useCallback(
    () => goToPayment(pagamento?.parametri?.publicKey as string, pagamento?.parametri?.sessionId as string),
    [pagamento]
  );

  return !proposta && !prodotto ? (
    <AppLoading />
  ) : (
    <React.Fragment>
      <Header showLogo={true} sizeLogo={LOGO_SIZE_SMALL} />
      <div className="status status_ko center">
        <Row className="mb2">
          <Col span={24}>
            <img src={ko} alt="Status - KO" width={100} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title className="fontWeightBold">Ops, qualcosa è andato storto</Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>L`acquisto della polizza {prodotto?.nome} non è andato a buon fine.</p>
            <p>Torna alla schermata di pagamento per fare un altro tentativo</p>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="center">
            <Button onClick={payment}>Torna al pagamento</Button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default StatusKo;
