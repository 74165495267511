import { proxy } from "valtio";
import { Prodotto } from "../../generated/api";

export interface ProdottoState {
  prodotto: Prodotto | null;
  loaded: boolean;
}

export const prodottoState: ProdottoState = proxy<ProdottoState>({
  prodotto: null,
  loaded: false,
});
