import React from "react";
import { numberToPrice } from "../../../shared/currency-utilis";

interface IProps {
  value?: number;
  toFixed?: number;
}

const ReadOnlyPrice: React.FC<IProps> = ({ value, toFixed = 2 }) => {
  return <>{numberToPrice(value, toFixed)}</>;
};

export default ReadOnlyPrice;
