import { Form, FormItemProps } from "antd";
import React from "react";
import Select from "react-select";
import { ISelectOption } from "../../types/ISelectOption";
import ReadOnlySelect from "./controls/ReadOnlySelect";
import { selectAsyncCustomStyle } from "./controls/selectAsyncCustomStyle";

interface IProps extends FormItemProps {
  allowClear?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  isMulti?: boolean;
  loading?: boolean;
  options: ISelectOption[];
  placeholder?: string;
  readonly?: boolean;
  isSearchable?: boolean;
}

const SelectField: React.FC<IProps> = ({
  allowClear,
  autoFocus,
  disabled,
  isMulti = false,
  loading = false,
  options = [],
  placeholder = "Seleziona...",
  isSearchable = true,
  readonly = false,
  ...rest
}) => {
  return (
    <Form.Item {...rest}>
      {readonly ? (
        <ReadOnlySelect isMulti={isMulti} options={options} />
      ) : (
        <Select
          autoFocus={autoFocus}
          isClearable={allowClear}
          isDisabled={disabled}
          isLoading={loading}
          isMulti={isMulti}
          placeholder={placeholder}
          options={options}
          styles={selectAsyncCustomStyle}
          isSearchable={isSearchable}
        />
      )}
    </Form.Item>
  );
};

export default SelectField;
