import { Moment } from "moment";
import React from "react";
import { DATE_FORMAT } from "../../../config/constants";
import { formatDate } from "../../../shared/date-utils";

interface IProps {
  format?: string;
  value?: Moment | null;
}

const ReadOnlyDate: React.FC<IProps> = ({ format = DATE_FORMAT, value }) => {
  return <div>{formatDate(format, value)}</div>;
};

export default ReadOnlyDate;
