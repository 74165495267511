import React from "react";

type IProps = {
  icon?: string;
};

const IconMini: React.FC<IProps> = ({ icon }) => {
  if (!icon) return null;

  return (
    <div className="icon--mini">
      <img src={icon} width={22} />
    </div>
  );
};

export default IconMini;
