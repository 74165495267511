import { Col, Divider, Row, Typography } from "antd";
import { useMemo } from "react";
import { FORM_GUTTER } from "../../../config/layout";
import { formattedToMmGgYyyy, getYearAgoDateMoment } from "../../../shared/date-utils";
import { loadOptionsComuni } from "../../../shared/options-utilis";
import {
  CAP,
  CELL_PHONE,
  CODICE_FISCALE,
  EMAIL,
  GIORNO_MESE_ANNO,
  NOT_AFTER,
  NOT_BEFORE,
  REQUIRED,
} from "../../../shared/validationRules";
import BooleanField from "../../fields/BooleanField";
import InputField from "../../fields/InputField";
import MaskField from "../../fields/MaskField";
import NumberField from "../../fields/NumberField";
import SelectAsyncField from "../../fields/SelectAsyncField";

const { Title } = Typography;

type IProps = {
  readonly?: boolean;
  contraenteAssicurato?: boolean;
};

const InfoContraente: React.FC<IProps> = ({ contraenteAssicurato = false, readonly = false }) => {
  const years70Ago = getYearAgoDateMoment(70);
  const years8Ago = getYearAgoDateMoment(8);

  const titleContraente = useMemo(
    () => (contraenteAssicurato === true ? "contraente/assicurato" : "contraente"),
    [contraenteAssicurato]
  );

  return (
    <>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <BooleanField
            name={["contraente", "assicurato"]}
            label="Il contraente è anche l'assicurato?"
            rules={[REQUIRED]}
            readonly={readonly}
            initialValue={true}
            className="mb0"
          />
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Divider />
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <Title level={5} className="fontWeightBold">
            Dati del {titleContraente}
          </Title>
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <InputField name={["contraente", "nome"]} label="Nome" rules={[REQUIRED]} readonly={readonly} />
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <InputField name={["contraente", "cognome"]} label="Cognome" rules={[REQUIRED]} readonly={readonly} />
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <SelectAsyncField
            label={"Luogo di nascita"}
            name={["contraente", "comuneDiNascita"]}
            readonly={readonly}
            placeholder="Ricerca..."
            rules={[REQUIRED]}
            loadOptions={loadOptionsComuni}
          />
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <MaskField
            name={["contraente", "dataDiNascita"]}
            mask={"99/99/9999"}
            placeholder={"GG/MM/YYYY"}
            label={"Data di nascita"}
            rules={[
              REQUIRED,
              GIORNO_MESE_ANNO,
              NOT_AFTER(years8Ago, formattedToMmGgYyyy, `Il ${titleContraente} ha meno di 8 anni`),
              NOT_BEFORE(years70Ago, formattedToMmGgYyyy, `Il ${titleContraente} ha più di 70 anni`),
            ]}
            readonly={readonly}
            inputMode="numeric"
          />
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <InputField
            name={["contraente", "codiceFiscale"]}
            label="Codice fiscale"
            rules={[REQUIRED, CODICE_FISCALE(["contraente", "nome"], ["contraente", "cognome"])]}
            className="uppercase"
            readonly={readonly}
          />
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <InputField name={["contraente", "indirizzo"]} label="Indirizzo" rules={[REQUIRED]} readonly={readonly} />
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <SelectAsyncField
            label={"Comune"}
            name={["contraente", "comune"]}
            readonly={readonly}
            placeholder="Ricerca..."
            rules={[REQUIRED]}
            loadOptions={loadOptionsComuni}
          />
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <InputField name={["contraente", "cap"]} label="CAP" rules={[REQUIRED, CAP]} readonly={readonly} />
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <InputField name={["contraente", "email"]} label="Email" rules={[REQUIRED, EMAIL]} readonly={readonly} />
        </Col>
      </Row>
      <Row gutter={FORM_GUTTER}>
        <Col span={24}>
          <NumberField
            name={["contraente", "telefono"]}
            label="Cellulare"
            rules={[REQUIRED, CELL_PHONE]}
            readonly={readonly}
          />
        </Col>
      </Row>
    </>
  );
};

export default InfoContraente;
