import React from "react";
import { ISelectOption } from "../../../types/ISelectOption";

interface IProps<T> {
  isMulti?: boolean;
  options?: ISelectOption<T>[];
  value?: ISelectOption<T> | ISelectOption<T>[] | null;
}

function ReadOnlySelect<T>({ isMulti = false, options, value }: IProps<T>) {
  if (isMulti) {
    const values = (value as ISelectOption<T>[])?.map((v) => v.value) || [];
    const labels = options?.filter((o) => values.includes(o.value)).map((o) => o.label) || [];
    return <React.Fragment>{labels.join(", ")}</React.Fragment>;
  } else {
    const option =
      options?.find((o) => o.value === ((value as ISelectOption<T>)?.value || (value as ISelectOption<T>))) ??
      (value as ISelectOption<T>);
    return <React.Fragment>{option?.label ?? "-"}</React.Fragment>;
  }
}

export default ReadOnlySelect;
