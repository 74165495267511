import { Form, FormItemProps } from "antd";
import React from "react";
import ReadOnlySelect from "./controls/ReadOnlySelect";
import SelectAsync, { Additional, LoadOptionsType } from "./controls/SelectAsync";

interface ICommonProps {
  additional?: Additional;
  isMulti?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

interface INormalProps extends FormItemProps, ICommonProps {
  readonly?: false;
  loadOptions: LoadOptionsType;
}

interface IReadOnlyProps extends FormItemProps, ICommonProps {
  readonly: true;
  loadOptions?: LoadOptionsType;
}

type IProps = INormalProps | IReadOnlyProps;

const SelectAsyncField: React.FC<IProps> = ({
  additional,
  disabled,
  isMulti = false,
  loadOptions,
  placeholder,
  readonly = false,
  ...rest
}) => {
  return (
    <Form.Item {...rest}>
      {readonly ? (
        <ReadOnlySelect />
      ) : (
        <SelectAsync<typeof isMulti>
          additional={additional}
          isMulti={isMulti}
          isDisabled={disabled}
          debounceTimeout={500}
          loadOptions={loadOptions as LoadOptionsType}
          placeholder={placeholder}
        />
      )}
    </Form.Item>
  );
};

export default SelectAsyncField;
