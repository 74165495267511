import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { Outlet } from "react-router-dom";
import { StylaFooter } from "../components/Footer";

const LayoutApp: React.FC = () => {
  return (
    <Layout className={"app"}>
      {/* <PageHeader /> */}
      <Content className="content">
        <Outlet />
      </Content>
      <StylaFooter />
    </Layout>
  );
};

export default LayoutApp;
