import React from "react";
import { IconType } from "react-icons/lib";
import { IconBaseProps } from "react-icons/lib/cjs/iconBase";

interface IProps extends IconBaseProps {
  Icon: IconType;
}

// Wrapper for react-icons
const WrapIcon: React.FC<IProps> = ({ children, Icon, ...rest }) => {
  const dashed = Icon.name.replace(/[A-Z]/g, (m) => "-" + m.toLowerCase());
  // It could be used IconContext.Provider (see https://github.com/react-icons/react-icons)
  return (
    <span role="img" aria-label={`${dashed}`} className={`anticon icon${dashed}`}>
      {children ? children : <Icon {...rest} />}
    </span>
  );
};

export default WrapIcon;
