/* tslint:disable */
/* eslint-disable */
/**
 * Styla Middleware
 * Styla magic middleware
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Assicurato
 */
export interface Assicurato {
    /**
     * 
     * @type {string}
     * @memberof Assicurato
     */
    'nome': string;
    /**
     * 
     * @type {string}
     * @memberof Assicurato
     */
    'cognome': string;
    /**
     * 
     * @type {string}
     * @memberof Assicurato
     */
    'codiceFiscale': string;
    /**
     * 
     * @type {string}
     * @memberof Assicurato
     */
    'indirizzo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Assicurato
     */
    'cap'?: string;
    /**
     * 
     * @type {Comune}
     * @memberof Assicurato
     */
    'comune'?: Comune;
    /**
     * 
     * @type {string}
     * @memberof Assicurato
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Assicurato
     */
    'dataDiNascita'?: string;
    /**
     * 
     * @type {Comune}
     * @memberof Assicurato
     */
    'comuneDiNascita'?: Comune;
    /**
     * 
     * @type {string}
     * @memberof Assicurato
     */
    'telefono'?: string;
    /**
     * impostato a false per il solo contraente se contraente NON é assicurato
     * @type {boolean}
     * @memberof Assicurato
     */
    'assicurato'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Assicurato
     */
    'altreInformazioni'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface Attivita
 */
export interface Attivita {
    /**
     * 
     * @type {string}
     * @memberof Attivita
     */
    'nome'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attivita
     */
    'logo'?: string;
}
/**
 * 
 * @export
 * @interface Compagnia
 */
export interface Compagnia {
    /**
     * 
     * @type {string}
     * @memberof Compagnia
     */
    'nome'?: string;
    /**
     * 
     * @type {string}
     * @memberof Compagnia
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Compagnia
     */
    'logo'?: string;
}
/**
 * 
 * @export
 * @interface Comune
 */
export interface Comune {
    /**
     * 
     * @type {number}
     * @memberof Comune
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Comune
     */
    'nome'?: string;
    /**
     * sigla provincia di appartenenza
     * @type {string}
     * @memberof Comune
     */
    'siglaProvincia'?: string;
}
/**
 * 
 * @export
 * @interface Conferma
 */
export interface Conferma {
    /**
     * la chiave che identifica univocamente questo campo
     * @type {string}
     * @memberof Conferma
     */
    'chiave': string;
    /**
     * 
     * @type {string}
     * @memberof Conferma
     */
    'testo': string;
    /**
     * 
     * @type {boolean}
     * @memberof Conferma
     */
    'obbligatorio': boolean;
}
/**
 * 
 * @export
 * @interface Copertura
 */
export interface Copertura {
    /**
     * 
     * @type {string}
     * @memberof Copertura
     */
    'nome': string;
    /**
     * 
     * @type {string}
     * @memberof Copertura
     */
    'descrizione': string;
    /**
     * 
     * @type {string}
     * @memberof Copertura
     */
    'dettaglio'?: string;
    /**
     * 
     * @type {Array<Documento>}
     * @memberof Copertura
     */
    'documenti': Array<Documento>;
    /**
     * 
     * @type {number}
     * @memberof Copertura
     */
    'massimale': number;
    /**
     * 
     * @type {number}
     * @memberof Copertura
     */
    'franchigia'?: number;
    /**
     * 
     * @type {string}
     * @memberof Copertura
     */
    'codiceCompagnia'?: string;
}
/**
 * Informazioni generali sul prodotto assicurativo
 * @export
 * @interface DatiCodiceFiscale
 */
export interface DatiCodiceFiscale {
    /**
     * 
     * @type {string}
     * @memberof DatiCodiceFiscale
     */
    'codiceFiscale': string;
    /**
     * 
     * @type {string}
     * @memberof DatiCodiceFiscale
     */
    'nome': string;
    /**
     * 
     * @type {string}
     * @memberof DatiCodiceFiscale
     */
    'cognome': string;
    /**
     * 
     * @type {string}
     * @memberof DatiCodiceFiscale
     */
    'genere'?: DatiCodiceFiscaleGenereEnum;
    /**
     * 
     * @type {number}
     * @memberof DatiCodiceFiscale
     */
    'idComuneDiNascita'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatiCodiceFiscale
     */
    'dataDiNascita'?: string;
}

export const DatiCodiceFiscaleGenereEnum = {
    M: 'M',
    F: 'F'
} as const;

export type DatiCodiceFiscaleGenereEnum = typeof DatiCodiceFiscaleGenereEnum[keyof typeof DatiCodiceFiscaleGenereEnum];

/**
 * 
 * @export
 * @interface Documento
 */
export interface Documento {
    /**
     * 
     * @type {string}
     * @memberof Documento
     */
    'nome'?: string;
    /**
     * 
     * @type {string}
     * @memberof Documento
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof Documento
     */
    'tipo'?: DocumentoTipoEnum;
}

export const DocumentoTipoEnum = {
    SetInformativo: 'SET_INFORMATIVO',
    Allegati: 'ALLEGATI',
    Allegato3: 'ALLEGATO_3',
    Allegato4: 'ALLEGATO_4',
    Allegato4Ter: 'ALLEGATO_4TER',
    Allegati4: 'ALLEGATI_4',
    Altro: 'ALTRO'
} as const;

export type DocumentoTipoEnum = typeof DocumentoTipoEnum[keyof typeof DocumentoTipoEnum];

/**
 * 
 * @export
 * @interface Esclusione
 */
export interface Esclusione {
    /**
     * 
     * @type {string}
     * @memberof Esclusione
     */
    'nome'?: string;
}
/**
 * 
 * @export
 * @interface InfoPagamento
 */
export interface InfoPagamento {
    /**
     * il nome del servizio di pagamento
     * @type {string}
     * @memberof InfoPagamento
     */
    'provider'?: InfoPagamentoProviderEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InfoPagamento
     */
    'parametri'?: { [key: string]: string; };
}

export const InfoPagamentoProviderEnum = {
    Stripe: 'STRIPE',
    BancaSella: 'BANCA_SELLA'
} as const;

export type InfoPagamentoProviderEnum = typeof InfoPagamentoProviderEnum[keyof typeof InfoPagamentoProviderEnum];

/**
 * 
 * @export
 * @interface Premio
 */
export interface Premio {
    /**
     * 
     * @type {number}
     * @memberof Premio
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Premio
     */
    'nome': string;
    /**
     * 
     * @type {string}
     * @memberof Premio
     */
    'tipo': PremioTipoEnum;
    /**
     * durata, espressa via https://en.wikipedia.org/wiki/ISO_8601#Durations
     * @type {string}
     * @memberof Premio
     */
    'unitaDurata'?: PremioUnitaDurataEnum;
    /**
     * durata minima 
     * @type {number}
     * @memberof Premio
     */
    'durataMin'?: number;
    /**
     * durataMax
     * @type {number}
     * @memberof Premio
     */
    'durataMax'?: number;
    /**
     * 
     * @type {string}
     * @memberof Premio
     */
    'dataFine': string;
    /**
     * 
     * @type {number}
     * @memberof Premio
     */
    'prezzo': number;
}

export const PremioTipoEnum = {
    Durata: 'DURATA',
    Periodo: 'PERIODO'
} as const;

export type PremioTipoEnum = typeof PremioTipoEnum[keyof typeof PremioTipoEnum];
export const PremioUnitaDurataEnum = {
    Giorno: 'GIORNO',
    Settimana: 'SETTIMANA',
    Mese: 'MESE',
    Anno: 'ANNO'
} as const;

export type PremioUnitaDurataEnum = typeof PremioUnitaDurataEnum[keyof typeof PremioUnitaDurataEnum];

/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof Problem
     */
    'type'?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof Problem
     */
    'title'?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof Problem
     */
    'status'?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof Problem
     */
    'detail'?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof Problem
     */
    'instance'?: string;
}
/**
 * Informazioni generali sul prodotto assicurativo
 * @export
 * @interface Prodotto
 */
export interface Prodotto {
    /**
     * 
     * @type {string}
     * @memberof Prodotto
     */
    'codice'?: string;
    /**
     * 
     * @type {Array<Documento>}
     * @memberof Prodotto
     */
    'documenti': Array<Documento>;
    /**
     * 
     * @type {Array<Attivita>}
     * @memberof Prodotto
     */
    'attivita': Array<Attivita>;
    /**
     * 
     * @type {string}
     * @memberof Prodotto
     */
    'nome': string;
    /**
     * 
     * @type {string}
     * @memberof Prodotto
     */
    'descrizione': string;
    /**
     * use ICON instead
     * @type {string}
     * @memberof Prodotto
     * @deprecated
     */
    'logo': string;
    /**
     * 
     * @type {string}
     * @memberof Prodotto
     */
    'icona'?: string;
    /**
     * 
     * @type {string}
     * @memberof Prodotto
     */
    'iconaMini'?: string;
    /**
     * 
     * @type {string}
     * @memberof Prodotto
     */
    'logoProdotto'?: string;
    /**
     * 
     * @type {Compagnia}
     * @memberof Prodotto
     */
    'compagnia': Compagnia;
    /**
     * 
     * @type {Array<Copertura>}
     * @memberof Prodotto
     */
    'coperture': Array<Copertura>;
    /**
     * 
     * @type {number}
     * @memberof Prodotto
     */
    'numeroMinPersone': number;
    /**
     * 
     * @type {number}
     * @memberof Prodotto
     */
    'numeroMaxPersone'?: number;
    /**
     * 
     * @type {Array<Premio>}
     * @memberof Prodotto
     */
    'premi': Array<Premio>;
    /**
     * 
     * @type {Array<Conferma>}
     * @memberof Prodotto
     */
    'campiAdeguatezza': Array<Conferma>;
    /**
     * 
     * @type {Array<Conferma>}
     * @memberof Prodotto
     */
    'campiPresaVisione': Array<Conferma>;
    /**
     * 
     * @type {Array<Esclusione>}
     * @memberof Prodotto
     */
    'esclusioni'?: Array<Esclusione>;
    /**
     * 
     * @type {string}
     * @memberof Prodotto
     */
    'mailDenunciaSinistri'?: string;
    /**
     * 
     * @type {string}
     * @memberof Prodotto
     */
    'indirizzoDenunciaSinistri'?: string;
}
/**
 * 
 * @export
 * @interface Proposta
 */
export interface Proposta {
    /**
     * identificativo univoco della proposta Styla
     * @type {string}
     * @memberof Proposta
     */
    'id': string;
    /**
     * il codice del prodotto
     * @type {string}
     * @memberof Proposta
     */
    'codiceProdotto'?: string;
    /**
     * 
     * @type {number}
     * @memberof Proposta
     */
    'idPremio'?: number;
    /**
     * il premio da pagare per la polizza
     * @type {string}
     * @memberof Proposta
     */
    'premio': string;
    /**
     * 
     * @type {Assicurato}
     * @memberof Proposta
     */
    'contraente': Assicurato;
    /**
     * 
     * @type {Array<Assicurato>}
     * @memberof Proposta
     */
    'altriAssicurati': Array<Assicurato>;
    /**
     * 
     * @type {Premio}
     * @memberof Proposta
     */
    'dettaglioPremio': Premio;
    /**
     * Data e ora di inizio della polizza. Indicativa, vale il momento del pagamento.
     * @type {string}
     * @memberof Proposta
     */
    'dataInizio': string;
    /**
     * Data e ora di fine della polizza
     * @type {string}
     * @memberof Proposta
     */
    'dataFine': string;
    /**
     * la durata (giorni, settimane), quando prevista
     * @type {number}
     * @memberof Proposta
     */
    'durata'?: number;
    /**
     * 
     * @type {string}
     * @memberof Proposta
     */
    'stato': PropostaStatoEnum;
}

export const PropostaStatoEnum = {
    Creata: 'CREATA',
    Confermata: 'CONFERMATA',
    PagamentoFallito: 'PAGAMENTO_FALLITO',
    Pagata: 'PAGATA',
    PolizzaEmessa: 'POLIZZA_EMESSA'
} as const;

export type PropostaStatoEnum = typeof PropostaStatoEnum[keyof typeof PropostaStatoEnum];

/**
 * 
 * @export
 * @interface RichiestaQuotazione
 */
export interface RichiestaQuotazione {
    /**
     * 
     * @type {Assicurato}
     * @memberof RichiestaQuotazione
     */
    'contraente': Assicurato;
    /**
     * i dati degli altri assicurati oltre al contraente, se presenti
     * @type {Array<Assicurato>}
     * @memberof RichiestaQuotazione
     */
    'altriAssicurati': Array<Assicurato>;
    /**
     * true se il contraente vuole essere assicurato, se false deve essere presente almeno un assicurato diverso dal contraente. DEPRECATO, usare il campo assicurato.assicurato
     * @type {boolean}
     * @memberof RichiestaQuotazione
     * @deprecated
     */
    'contraenteAssicurato'?: boolean;
    /**
     * per ogni campo di adeguatezza, specifica se è stato confermato o meno dall\'utente
     * @type {{ [key: string]: boolean; }}
     * @memberof RichiestaQuotazione
     */
    'campiAdeguatezza': { [key: string]: boolean; };
    /**
     * il codice del prodotto. L\'id del premio deve essere riferito a questo prodotto
     * @type {string}
     * @memberof RichiestaQuotazione
     */
    'codiceProdotto': string;
    /**
     * l\'id della tipologia di premio
     * @type {number}
     * @memberof RichiestaQuotazione
     */
    'idPremio': number;
    /**
     * 
     * @type {string}
     * @memberof RichiestaQuotazione
     */
    'dataInizio'?: string;
    /**
     * la durata della polizza, compresa tra il min e il max del premio di riferimento
     * @type {number}
     * @memberof RichiestaQuotazione
     */
    'durata': number;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof RichiestaQuotazione
     */
    'campiPresaVisione': { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface SistemaPagamento
 */
export interface SistemaPagamento {
    /**
     * Il nome del provider di pagamento
     * @type {string}
     * @memberof SistemaPagamento
     */
    'nome'?: string;
    /**
     * Parametri per il sistema di pagamento, dinamici
     * @type {{ [key: string]: object; }}
     * @memberof SistemaPagamento
     */
    'parametri'?: { [key: string]: object; };
}

/**
 * LocalitaApi - axios parameter creator
 * @export
 */
export const LocalitaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Ritorna la lista dei comuni
         * @summary Lista dei comuni
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComuni: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/localita/comuni`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalitaApi - functional programming interface
 * @export
 */
export const LocalitaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalitaApiAxiosParamCreator(configuration)
    return {
        /**
         * Ritorna la lista dei comuni
         * @summary Lista dei comuni
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComuni(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comune>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComuni(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocalitaApi - factory interface
 * @export
 */
export const LocalitaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalitaApiFp(configuration)
    return {
        /**
         * Ritorna la lista dei comuni
         * @summary Lista dei comuni
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComuni(search?: string, options?: any): AxiosPromise<Array<Comune>> {
            return localVarFp.getComuni(search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalitaApi - object-oriented interface
 * @export
 * @class LocalitaApi
 * @extends {BaseAPI}
 */
export class LocalitaApi extends BaseAPI {
    /**
     * Ritorna la lista dei comuni
     * @summary Lista dei comuni
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalitaApi
     */
    public getComuni(search?: string, options?: AxiosRequestConfig) {
        return LocalitaApiFp(this.configuration).getComuni(search, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProdottoApi - axios parameter creator
 * @export
 */
export const ProdottoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ritorna le informazioni principali sul prodotto assicurativo
         * @param {string} codice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProdotto: async (codice: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codice' is not null or undefined
            assertParamExists('getProdotto', 'codice', codice)
            const localVarPath = `/prodotto/{codice}`
                .replace(`{${"codice"}}`, encodeURIComponent(String(codice)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProdottoApi - functional programming interface
 * @export
 */
export const ProdottoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProdottoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Ritorna le informazioni principali sul prodotto assicurativo
         * @param {string} codice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProdotto(codice: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Prodotto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProdotto(codice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProdottoApi - factory interface
 * @export
 */
export const ProdottoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProdottoApiFp(configuration)
    return {
        /**
         * 
         * @summary Ritorna le informazioni principali sul prodotto assicurativo
         * @param {string} codice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProdotto(codice: string, options?: any): AxiosPromise<Prodotto> {
            return localVarFp.getProdotto(codice, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProdottoApi - object-oriented interface
 * @export
 * @class ProdottoApi
 * @extends {BaseAPI}
 */
export class ProdottoApi extends BaseAPI {
    /**
     * 
     * @summary Ritorna le informazioni principali sul prodotto assicurativo
     * @param {string} codice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProdottoApi
     */
    public getProdotto(codice: string, options?: AxiosRequestConfig) {
        return ProdottoApiFp(this.configuration).getProdotto(codice, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropostaApi - axios parameter creator
 * @export
 */
export const PropostaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Conferma la proposta per inizializzare la procedura di pagamento
         * @param {string} id L\&#39;id della proposta styla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confermaProposta: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('confermaProposta', 'id', id)
            const localVarPath = `/proposta/{id}/conferma`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invia i dati del contraente e le conferme di adeguatezza e presa visione. Il sistema li valida e risponde con una proposta di polizza.
         * @summary invia i dati dell\'utente e ritorna una proposta di polizza
         * @param {RichiestaQuotazione} richiestaQuotazione 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creaProposta: async (richiestaQuotazione: RichiestaQuotazione, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'richiestaQuotazione' is not null or undefined
            assertParamExists('creaProposta', 'richiestaQuotazione', richiestaQuotazione)
            const localVarPath = `/proposta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(richiestaQuotazione, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recupera i dettagli della proposta di polizza
         * @param {string} id L\&#39;id della proposta di polizza Styla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recuperaProposta: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('recuperaProposta', 'id', id)
            const localVarPath = `/proposta/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropostaApi - functional programming interface
 * @export
 */
export const PropostaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropostaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Conferma la proposta per inizializzare la procedura di pagamento
         * @param {string} id L\&#39;id della proposta styla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confermaProposta(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoPagamento>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confermaProposta(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invia i dati del contraente e le conferme di adeguatezza e presa visione. Il sistema li valida e risponde con una proposta di polizza.
         * @summary invia i dati dell\'utente e ritorna una proposta di polizza
         * @param {RichiestaQuotazione} richiestaQuotazione 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creaProposta(richiestaQuotazione: RichiestaQuotazione, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Proposta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creaProposta(richiestaQuotazione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recupera i dettagli della proposta di polizza
         * @param {string} id L\&#39;id della proposta di polizza Styla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recuperaProposta(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Proposta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recuperaProposta(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropostaApi - factory interface
 * @export
 */
export const PropostaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropostaApiFp(configuration)
    return {
        /**
         * 
         * @summary Conferma la proposta per inizializzare la procedura di pagamento
         * @param {string} id L\&#39;id della proposta styla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confermaProposta(id: string, options?: any): AxiosPromise<InfoPagamento> {
            return localVarFp.confermaProposta(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Invia i dati del contraente e le conferme di adeguatezza e presa visione. Il sistema li valida e risponde con una proposta di polizza.
         * @summary invia i dati dell\'utente e ritorna una proposta di polizza
         * @param {RichiestaQuotazione} richiestaQuotazione 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creaProposta(richiestaQuotazione: RichiestaQuotazione, options?: any): AxiosPromise<Proposta> {
            return localVarFp.creaProposta(richiestaQuotazione, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recupera i dettagli della proposta di polizza
         * @param {string} id L\&#39;id della proposta di polizza Styla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recuperaProposta(id: string, options?: any): AxiosPromise<Proposta> {
            return localVarFp.recuperaProposta(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropostaApi - object-oriented interface
 * @export
 * @class PropostaApi
 * @extends {BaseAPI}
 */
export class PropostaApi extends BaseAPI {
    /**
     * 
     * @summary Conferma la proposta per inizializzare la procedura di pagamento
     * @param {string} id L\&#39;id della proposta styla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropostaApi
     */
    public confermaProposta(id: string, options?: AxiosRequestConfig) {
        return PropostaApiFp(this.configuration).confermaProposta(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invia i dati del contraente e le conferme di adeguatezza e presa visione. Il sistema li valida e risponde con una proposta di polizza.
     * @summary invia i dati dell\'utente e ritorna una proposta di polizza
     * @param {RichiestaQuotazione} richiestaQuotazione 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropostaApi
     */
    public creaProposta(richiestaQuotazione: RichiestaQuotazione, options?: AxiosRequestConfig) {
        return PropostaApiFp(this.configuration).creaProposta(richiestaQuotazione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recupera i dettagli della proposta di polizza
     * @param {string} id L\&#39;id della proposta di polizza Styla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropostaApi
     */
    public recuperaProposta(id: string, options?: AxiosRequestConfig) {
        return PropostaApiFp(this.configuration).recuperaProposta(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ValidazioneApi - axios parameter creator
 * @export
 */
export const ValidazioneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Validazione del codice fiscale sulla base di nome, cognome e altri parametri facoltativi
         * @summary Validazione codice fiscale
         * @param {DatiCodiceFiscale} datiCodiceFiscale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificaCodiceFiscale: async (datiCodiceFiscale: DatiCodiceFiscale, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datiCodiceFiscale' is not null or undefined
            assertParamExists('verificaCodiceFiscale', 'datiCodiceFiscale', datiCodiceFiscale)
            const localVarPath = `/codice-fiscale/valida`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datiCodiceFiscale, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValidazioneApi - functional programming interface
 * @export
 */
export const ValidazioneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValidazioneApiAxiosParamCreator(configuration)
    return {
        /**
         * Validazione del codice fiscale sulla base di nome, cognome e altri parametri facoltativi
         * @summary Validazione codice fiscale
         * @param {DatiCodiceFiscale} datiCodiceFiscale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificaCodiceFiscale(datiCodiceFiscale: DatiCodiceFiscale, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificaCodiceFiscale(datiCodiceFiscale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ValidazioneApi - factory interface
 * @export
 */
export const ValidazioneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValidazioneApiFp(configuration)
    return {
        /**
         * Validazione del codice fiscale sulla base di nome, cognome e altri parametri facoltativi
         * @summary Validazione codice fiscale
         * @param {DatiCodiceFiscale} datiCodiceFiscale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificaCodiceFiscale(datiCodiceFiscale: DatiCodiceFiscale, options?: any): AxiosPromise<void> {
            return localVarFp.verificaCodiceFiscale(datiCodiceFiscale, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ValidazioneApi - object-oriented interface
 * @export
 * @class ValidazioneApi
 * @extends {BaseAPI}
 */
export class ValidazioneApi extends BaseAPI {
    /**
     * Validazione del codice fiscale sulla base di nome, cognome e altri parametri facoltativi
     * @summary Validazione codice fiscale
     * @param {DatiCodiceFiscale} datiCodiceFiscale 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValidazioneApi
     */
    public verificaCodiceFiscale(datiCodiceFiscale: DatiCodiceFiscale, options?: AxiosRequestConfig) {
        return ValidazioneApiFp(this.configuration).verificaCodiceFiscale(datiCodiceFiscale, options).then((request) => request(this.axios, this.basePath));
    }
}


