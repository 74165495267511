import { Button, Col, Form, Row, Typography } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSnapshot } from "valtio";
import Header from "../../components/Header";
import { FORM_GUTTER } from "../../config/layout";
import { Assicurato } from "../../generated/api";
import { formattedToMmGgYyyy, mmGgYyyyToFormatted } from "../../shared/date-utils";
import { comuneToOption } from "../../shared/options-utilis";
import { goToNextSteps, setStepData } from "../../store/steps/steps.actions";
import { stepsState } from "../../store/steps/steps.state";
import { Step } from "../../types/generics";
import { ISelectOption } from "../../types/ISelectOption";
import InfoAltriAssicurati from "./parts/InfoAltriAssicurati";
import InfoContraente from "./parts/InfoContraente";

const currentStep = 3;
const { Title } = Typography;

export interface FormRecordContraente extends Omit<Assicurato, "comune" | "comuneDiNascita" | "dataDiNascita"> {
  comune?: ISelectOption;
  dataDiNascita?: string;
  comuneDiNascita?: ISelectOption;
}

export interface FormRecord extends Omit<Step, "contraente"> {
  contraente?: FormRecordContraente;
}

const formRecordToStep = (record: FormRecord): Step => {
  return {
    ...record,
    altriAssicurati: record.altriAssicurati ?? [],
    contraente: {
      ...record.contraente,
      comune: { id: record.contraente?.comune?.value as number, nome: record.contraente?.comune?.label },
      comuneDiNascita: {
        id: record.contraente?.comuneDiNascita?.value as number,
        nome: record.contraente?.comuneDiNascita?.label,
      },
      dataDiNascita: formattedToMmGgYyyy(record.contraente?.dataDiNascita),
    },
  };
};

const stepToFormRecord = (step: Step): FormRecord => {
  const { contraente } = step;
  return {
    ...step,
    altriAssicurati: step.altriAssicurati ?? [],
    contraente: {
      ...contraente,
      dataDiNascita: contraente?.dataDiNascita ? mmGgYyyyToFormatted(contraente.dataDiNascita) : undefined,
      comune: contraente?.comune ? comuneToOption(contraente?.comune) : undefined,
      comuneDiNascita: contraente?.comuneDiNascita ? comuneToOption(contraente?.comuneDiNascita) : undefined,
    },
  };
};

const Step3 = () => {
  const [form] = Form.useForm<FormRecord>();
  const { data } = useSnapshot(stepsState);
  const { getFieldsValue, setFieldsValue } = form;
  const contraenteAssicurato = Form.useWatch(["contraente", "assicurato"], form);

  const numeroAssicurati = data ? (data.persone as number) : 0;
  const numeroAltriAssicurati = useMemo(() => {
    if (numeroAssicurati === 1 && contraenteAssicurato === true) {
      return 0;
    } else if (numeroAssicurati === 1 && contraenteAssicurato === false) {
      return 1;
    } else if (numeroAssicurati > 1 && contraenteAssicurato === true) {
      return numeroAssicurati - 1;
    } else if (numeroAssicurati > 1 && contraenteAssicurato === false) {
      return numeroAssicurati;
    } else {
      return 0;
    }
  }, [numeroAssicurati, contraenteAssicurato]);

  const saveStepValue = useCallback(() => {
    const values = getFieldsValue();
    const step = formRecordToStep(values);
    setStepData(step);
  }, [getFieldsValue]);

  const save = useCallback(() => {
    saveStepValue();
    goToNextSteps();
  }, [saveStepValue]);

  useEffect(() => {
    if (data) {
      setFieldsValue(stepToFormRecord(data));
    }
  }, [data, setFieldsValue]);

  return (
    <React.Fragment>
      <Header prevStep="Torna a Coperture" saveStep={saveStepValue} />
      <div className="step">
        <Title className="fontWeightBold">Dati assicurati</Title>
        <Form
          name={"step" + currentStep}
          form={form}
          layout="vertical"
          onFinish={save}
          scrollToFirstError={true}
          className={"mt2"}
        >
          <InfoContraente contraenteAssicurato={contraenteAssicurato} />
          <InfoAltriAssicurati numeroAltriAssicurati={numeroAltriAssicurati} />
          <Row gutter={FORM_GUTTER} className="center mt2 mb1">
            <Col span={24}>
              <Button htmlType="submit">Continua</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default Step3;
