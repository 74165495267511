import { API_PATH } from "../config/constants";
import { Configuration, LocalitaApi, ProdottoApi, PropostaApi, ValidazioneApi } from "../generated/api";

const basePath: string = process.env.API_BASE_PATH || API_PATH;

const configuration = new Configuration({ basePath });

function bindMethods<T extends object>(klass: T): T {
  Object.getOwnPropertyNames(klass.constructor.prototype).forEach((method) => {
    if (method !== "constructor") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      klass[method] = klass[method].bind(klass);
    }
  });
  return klass;
}

export const api = {
  prodotto: bindMethods(new ProdottoApi(configuration)),
  proposta: bindMethods(new PropostaApi(configuration)),
  localita: bindMethods(new LocalitaApi(configuration)),
  validazione: bindMethods(new ValidazioneApi(configuration)),
};
