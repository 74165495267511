import { Button } from "antd";
import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import logoStylaCare from "../assets/svg/logoStylaCare.svg";
import { CUSTOMER_NAME } from "../config/constants";
import { LOGO_SIZE_NORMAL } from "../config/layout";
import { goToPrevSteps } from "../store/steps/steps.actions";
import WrapIcon from "./WrapIcon";

type IProps = {
  showLogo?: boolean;
  sizeLogo?: number;
  prevStep?: string;
  goTo?: string;
  navigateTo?: string;
  saveStep?: () => void;
};

const Header: React.FC<IProps> = ({
  showLogo = false,
  sizeLogo = LOGO_SIZE_NORMAL,
  prevStep,
  saveStep,
  goTo,
  navigateTo,
}) => {
  const navigate = useNavigate();
  return (
    <div className="header">
      {showLogo && (
        <div className="logo">
          <img src={logoStylaCare} alt={CUSTOMER_NAME} width={sizeLogo} />
        </div>
      )}
      {prevStep && (
        <div className="prevStep">
          <Button
            icon={<WrapIcon Icon={FiArrowLeft} />}
            type="text"
            onClick={() => {
              goToPrevSteps();
              saveStep && saveStep();
            }}
          >
            {prevStep}
          </Button>
        </div>
      )}
      {goTo && navigateTo && (
        <div className="prevStep">
          <Button icon={<WrapIcon Icon={FiArrowLeft} />} type="text" onClick={() => navigate(navigateTo)}>
            {goTo}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Header;
