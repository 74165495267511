import { DatePicker, Form, FormItemProps } from "antd";
import { DatePickerProps } from "antd/lib/date-picker";
import locale from "antd/lib/date-picker/locale/it_IT";
import { Moment } from "moment";
import "moment/locale/it";
import React from "react";
import { DATE_FORMAT } from "../../config/constants";
import ReadOnlyDate from "./controls/ReadOnlyDate";

export const disableDateRanges = (disableBefore?: Moment | null, disableAfter?: Moment | null) => {
  if (!disableBefore && !disableAfter) return;
  const disableBeforeDate = disableBefore?.format("YYYY-MM-DD") ?? "";
  const disableAfterDate = disableAfter?.format("YYYY-MM-DD") ?? "";

  return function disabledDate(current: Moment) {
    const currentDate = current.format("YYYY-MM-DD");
    if (disableBeforeDate && disableAfterDate) {
      return currentDate < disableBeforeDate || currentDate > disableAfterDate;
    }
    if (disableBeforeDate) {
      return currentDate < disableBeforeDate;
    }
    if (disableAfterDate) {
      return currentDate > disableAfterDate;
    }
    return false;
  };
};

interface IProps extends FormItemProps {
  allowClear?: boolean;
  autoFocus?: boolean;
  disableAfter?: Moment | null;
  disableBefore?: Moment | null;
  disabled?: boolean;
  format?: string;
  onChange?: DatePickerProps["onChange"];
  readonly?: boolean;
  styleDatePicker?: DatePickerProps["style"];
}

const DateField: React.FC<IProps> = ({
  allowClear,
  autoFocus,
  disableAfter,
  disableBefore,
  disabled,
  format = DATE_FORMAT,
  onChange,
  readonly = false,
  styleDatePicker,
  ...rest
}) => {
  return (
    <Form.Item {...rest}>
      {readonly ? (
        <ReadOnlyDate format={format} />
      ) : (
        <DatePicker
          locale={locale}
          allowClear={allowClear}
          autoFocus={autoFocus}
          disabled={disabled}
          format={format}
          disabledDate={disableDateRanges(disableBefore, disableAfter)}
          onChange={onChange}
          style={styleDatePicker}
          inputReadOnly={true} // Disable keyboard
        />
      )}
    </Form.Item>
  );
};

export default DateField;
