import axios, { CancelTokenSource } from "axios";

class AxiosScope {
  private readonly scope: Record<string, CancelTokenSource>;

  constructor() {
    this.scope = {};
  }

  init(scope: string) {
    if (!this.scope[scope]) {
      this.scope[scope] = axios.CancelToken.source();
    }
  }

  get(scope: string, cancelPrevious = false) {
    if (cancelPrevious) this.cancel(scope);
    this.init(scope);
    const { token } = this.scope[scope];
    return {
      cancelToken: token,
      cancel: () => this.cancel(scope),
      clear: () => this.clear(scope),
    };
  }

  cancel(scope: string) {
    this.scope[scope]?.cancel();
    this.clear(scope);
  }

  clear(scope: string) {
    delete this.scope[scope];
  }
}

export const axiosScope = new AxiosScope();
